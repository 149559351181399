/**
 * 工作量统计
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Card, Tabs, Table, Radio, DatePicker, Tooltip, Menu, Dropdown } from 'antd';
import numeral from 'numeral';
import { XInput, XInputNum, XSelect, XOKButton, XCancelButton} from '@/components/xqxc_ui';
import { ChartCard, yuan, MiniArea, MiniBar, MiniProgress, Field, Bar, Pie, TimelineChart } from 'ant-design-pro/lib/Charts';
import Trend from 'ant-design-pro/lib/Trend';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import { connect } from 'react-redux';

import { getTimeDistance } from '../utils/utils';
import TableContent from './components/TableContent';
import { SearchBox, SearchItem } from '@/components/Layout';
import { setBreadcrumbPaths } from '@/reducers/home/actions';

import styles from './index.module.scss';


const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
class Main extends React.Component {
    state = {
        loading: false,
        salesType: 'all',
        currentTabKey: '',
        rangePickerValue: [],
        tempData: {},
    }

    componentDidMount() {
        this.props.setBreadcrumbPaths(this.props.match); // 自动识别面包屑

    }

    componentWillUnmount() {
    
    }
    handleChangeSalesType = (e) => {
        this.setState({
            salesType: e.target.value,
        });
    }

    handleTabChange = (key) => {
        this.setState({
            currentTabKey: key,
        });
    }

    handleRangePickerChange = (rangePickerValue) => {
        this.setState({
            rangePickerValue,
        });

        // this.props.dispatch({
        //   type: 'chart/fetchSalesData',
        // });
    }

    selectDate = (type) => {
        this.setState({
            rangePickerValue: getTimeDistance(type),
        });

        // this.props.dispatch({
        //   type: 'chart/fetchSalesData',
        // });
    }

    isActive(type) {
        const { rangePickerValue } = this.state;
        const value = getTimeDistance(type);
        if (!rangePickerValue[0] || !rangePickerValue[1]) {
            return;
        }
        if (rangePickerValue[0].isSame(value[0], 'day') && rangePickerValue[1].isSame(value[1], 'day')) {
            return styles.currentDate;
        }
    }
    render() {
        return (
            <>
            <Row>
            <SearchBox>
                <SearchItem>
                <RangePicker
                    value={this.state.rangePickerValue}
                    onChange={this.handleRangePickerChange}
                    style={{ width: 256}}
                />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        style={{marginRight:0, marginTop:'-4px'}}
                        placeholder='请选择'
                        renderData={[]}
                        dataIndex='areaName'
                        keyIndex='id'
                        value="全部商户"
                        bindThis={this}
                        bindName='cityItem'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        style={{marginRight:0, marginTop:'-4px'}}
                        placeholder='请选择'
                        renderData={[]}
                        dataIndex='areaName'
                        keyIndex='id'
                        value="全部店铺"
                        bindThis={this}
                        bindName='cityItem'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        style={{ marginTop:'-4px'}}
                        placeholder='请选择'
                        renderData={[]}
                        dataIndex='areaName'
                        keyIndex='id'
                        value="全部客服"
                        bindThis={this}
                        bindName='cityItem'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                 <XOKButton label='查询' style={{marginLeft:'10px', marginTop:'-4px'}}/>
                </SearchItem>
            </SearchBox> 
            </Row>
           <Row style={{marginTop:'15px'}}>
              <div className={styles.statsPanel}>
               <div>

                   <p>平均首次响应时长</p>
                   <p>230s</p>
                   <p>转接后平均首次响应时长40s</p>
               </div>
               <div>
                   <p>平均响应时长</p>
                   <p>36s</p>
                   <p>转接后平均响应时长40s</p>
               </div>
               <div>
                   <p>好评率</p>
                   <p>99%</p>
                   <p>客户评价率 78%</p>
               </div>
               
              </div>
           </Row>
           <Row justify="space-between" style={{marginTop:'40px'}}>
            <Col span={10}>
                <Bar color="#1677ff" height={255} title='平均首次响应时长统计' data={tempData.salesData} />
            </Col>
            <Col span={10}>
                <Bar color="#1677ff" heirenderght={255} title='平均响应时长统计' data={tempData.salesData} />
            </Col>
           </Row>
           <Row style={{margin:'40px 0'}}>
           <div style={{ width:'500px'}}>
                <h4 style={{ marginTop: 8, marginBottom: '20px' }}>评价占比</h4>
                <Pie
                    hasLegend
                    total={null}
                    data={tempData.salesTypeData}
                    height={200}
                    lineWidth={1}
                />
                </div>
           </Row>

           <TableContent
                renderData={[]}
                tableAction={() => {}}
                paginationChange={() => {}}
            />
           
            </>
        );
    }

}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    setBreadcrumbPaths: PropTypes.func,
};
const mapStateToProps = (state) => ({});

export default connect(null, {
    setBreadcrumbPaths
})(Main);
