/**
 * 主页
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import NavCard from './components/NavCard';
import NavPosition from './components/NavPosition';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AllActions from '@/reducers/home/actions';
import { Avatar, message } from 'antd';
import { setLocalStorage, getLocalStorage, removeSessionStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';

class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isToggleCard: false, // 用于区别当前用户点击的是一级导航还是二级导航，true表示点击一级导航
            stretchOpen: this.props.stretchOpen, // 导航栏的伸展状态
            nick: '', // 用户名
            avatar: '', // 头像
        };
        this.mainContainerRef = React.createRef(null);
    }

    componentDidMount() {
        const userInfo = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')) : null;
        if (userInfo == null) {
            this.props.history.push({ pathname: '/' });
            setTimeout(() => { location.reload(); }, 500);
        } else {
            const { nick, avatar } = userInfo;
            this.setState({ nick, avatar });
            // this.props.getMenuList(); // 一期不考虑权限管理
        }
    }

    // componentDidUpdate(prevProps) {
    //     if(this.props.location.pathname !== prevProps.location.pathname) {
    //         this.props.setFirstCardPath(this.props.location.pathname);
    //     }
    // }

    // 用户点击一级导航回调函数
    _toggleCardHandle = (path) => {
        this.props.setFirstCardPath(path);
        this.setState({ isToggleCard: true, stretchOpen: true });
        if (path === '/home/cglobal') {
            this.props.history.push({
                pathname: path
            });
        }

    }

    // 用户点击二级导航回调函数
    _putRouteHandle = (secondCardPath, breadcrumbPaths) => {
        this.props.setSecondCardPath(secondCardPath);
        this.setState({ isToggleCard: false });
        // 清空页面缓存，与KeepAlive配合使用
        const href = window.location.href;
        const list = href.split('/');
        let cacheName = '';
        if (list[list.length - 1] == '') {
            cacheName = list[list.length - 3] + '/' + list[list.length - 2];
        } else {
            cacheName = list[list.length - 2] + '/' + list[list.length - 1];
        }
        removeSessionStorage(cacheName);
        this.props.history.push({
            pathname: secondCardPath
        });
    }

    // 渲染手风琴导航组件
    _renderNav = (routes) => {
        return (
            <div className={styles.nav} style={this.state.stretchOpen ? { width: '208px', backgroundColor: '#323232' } : { width: '0px', backgroundColor: '#323232' }}>
                {
                    routes.map((rItem) => {
                        let eleNode = null;
                        if (rItem.path != '/' && rItem.path != '/setting') {
                            eleNode = this._renderNavCard(rItem);
                        }
                        return eleNode;
                    })
                }
            </div>
        );
    }

    // 渲染手风琴导航组件各个项
    _renderNavCard = (rItem) => {
        return (
            <NavCard
                key={rItem.path}
                rItem={rItem}
                isToggleCard={this.state.isToggleCard}
                match={this.props.match}
                history={this.props.history}
                putRouteHandle={this._putRouteHandle}
                toggleCardHandle={this._toggleCardHandle}
                firstCardPath={this.props.firstCardPath}
                secondCardPath={this.props.secondCardPath}
                stretchOpen={this.state.stretchOpen}
            />
        );
    }

    // 渲染路由页面，随着导航点击响应页面变化
    _renderRoute = (routes) => {
        let routeList = [];
        routes.forEach((rItem) => {
            let route = {};
            if (rItem.component) {
                route.key = this.props.match.path + rItem.path;
                route.path = this.props.match.path + rItem.path;
                route.component = rItem.component;
                routeList.push(route);
            }
            if (rItem.childrenNode) {
                rItem.childrenNode.forEach((rItemChild) => {
                    let cr = {};
                    cr.key = this.props.match.path + rItem.path + rItemChild.path;
                    cr.path = this.props.match.path + rItem.path + rItemChild.path;
                    cr.component = rItemChild.component;
                    routeList.push(cr);
                });
            }
        });
        return (
            routeList.map((route) => {
                return (
                    <Route
                        exact
                        key={route.key}
                        path={route.path}
                        component={
                            route.component
                        }
                    />
                );
            })
        );
    }

    // 界面伸缩，当该函数触发时导航栏收缩至只剩图标
    _drawerHandle = () => {
        this.props.setStretchOpen(!this.state.stretchOpen);
        this.setState({ stretchOpen: !this.state.stretchOpen });
    }

    // 数组对象类型深度拷贝算法
    _deepCopy(obj) {
        if (typeof obj !== 'object') {
            return obj;
        }
        var res = Array.isArray(obj) ? [] : {};
        for (let i in obj) {
            if (i == 'component') {
                res[i] = obj[i];
            } else {
                res[i] = this._deepCopy(obj[i]);
            }
        }
        return res;
    }

    // 筛选菜单权限
    _getMenuRoutes = (propRoutes, menuList) => {
        const auth = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')) : null;
        // 如果页面打开时没有查到用户信息，则返回登录页面
        if (auth == null) {
            this.props.history.push({ pathname: '/' });
            setTimeout(() => { location.reload(); }, 500);
            return;
        }

        const routes = this._deepCopy(propRoutes);
        const { isAdmin } = auth;

        // 超级管理员返回全部菜单
        if (isAdmin == 1) {
            return routes;
        }

        // let mRoutes = routes.map((rItem) => {
        //     if (rItem.path == '/' || rItem.path == '/setting') {
        //         return rItem;
        //     } else {
        //         const pathUrl = rItem.path.substring(1, rItem.path.length);
        //         let boolean = false;
        //         let menuItem = {};
        //         for (let i = 0; i < menuList.length; i++) {
        //             if (pathUrl == menuList[i].menuUrl) {
        //                 menuItem = menuList[i];
        //                 boolean = true;
        //                 break;
        //             }
        //         }
        //         let newRoute = {};
        //         if (boolean) {
        //             newRoute = { ...rItem, childrenNode: [] };
        //             const childrenNode = rItem.childrenNode;
        //             const menuItemChildrens = menuItem.childrens;
        //             for (let m = 0; m < childrenNode.length; m++) {
        //                 const childrenNodePath = childrenNode[m].path;
        //                 // 仅root、lxy这两个账号显示该菜单
        //                 if ((loginUserId == '1929652261335922' || loginUserId == '1929666043349520')) {
        //                     if (rItem.path == '/storehousefinancialmanage' && childrenNodePath.indexOf('/datacenter') != -1) {
        //                         newRoute.childrenNode.push(childrenNode[m]);
        //                     }
        //                     if (rItem.path == '/workbench' && childrenNodePath == '/datafinancial') {
        //                         newRoute.childrenNode.push(childrenNode[m]);
        //                     }
        //                 }
        //                 for (let n = 0; n < menuItemChildrens.length; n++) {
        //                     const menuUrl = menuItemChildrens[n].menuUrl;
        //                     if (childrenNodePath == menuUrl || childrenNodePath == ('/' + menuUrl) || (childrenNodePath.indexOf(menuUrl) > -1 && childrenNode[m].show == 'none')) {
        //                         newRoute.childrenNode.push(childrenNode[m]);
        //                         break;
        //                     }
        //                 }
        //             }
        //             return newRoute;
        //         }
        //     }
        // });

        // mRoutes = mRoutes.filter((item) => {
        //     if (item != undefined) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // });

        // console.log('mRoutes', mRoutes);
        // if (process.env.NODE_ENV === 'production') {
        //     return mRoutes; // 这段代码不要动
        // } else {
        //     return mRoutes;
        // }
    }

    // 退出
    _logout = () => {
        http('/?cmd=com.xqxc.api.sys.SysAdminService.loginOut', {}, 'POST').then((response) => {
            message.success('退出成功');
            this.props.history.push({ pathname: '/' });
        }).catch((e) => {
            if (e && e.message) message.error(e.message);
        });
    }

    // 打开个人设置
    _settingHandle = () => {
        this.props.history.push({ pathname: '/home/setting' });
    }

    _saveButtonPermission = (menuList) => {
        const premission = this._getButtonUrlForChildren(menuList);
        setLocalStorage('btnPremission', premission);
    }

    _getButtonUrlForChildren = (treeData) => {
        let childrenEventKeys = [];
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
                childrenEventKeys.push(treeData[i].menuUrl);
            }
            if (treeData[i].childrens && treeData[i].childrens.length > 0) {
                const array = this._getButtonUrlForChildren(treeData[i].childrens);
                childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
            }
        }
        return childrenEventKeys;
    }

    render() {
        const { routes, menuList, history, match } = this.props;
        const { nick, avatar, stretchOpen } = this.state;
        const mRoutes = this._getMenuRoutes(routes, menuList);
        this._saveButtonPermission(menuList); // 获取按钮权限

        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        商云客服后台管理系统
                    </div>
                    <div className={styles.help}>
                        <div className={styles.other}>
                            <div className={styles.userinfo}>
                                <Avatar style={{ minWidth: '24px', minHeight: '24px', backgroundColor: '#A6DBFF' }} size={24} icon="U" />
                                <div className={styles.userinfo_name}>{nick}</div>
                            </div>
                        </div>
                        <div className={styles.logout} onClick={this._logout}>
                            <img className={styles.icon} src={require('@/assets/image/logout.png').default} />
                        </div>
                    </div>
                </div>
                <div className={styles.body}>
                    {this._renderNav(mRoutes)}
                    <div className={styles.content} id='home_content'>
                        <div className={styles.sbox}>
                            {/* <div className={styles.sitem} onClick={this._drawerHandle}><span className={stretchOpen ? styles.letfsicon : styles.rightsicon}></span></div> */}
                        </div>
                        <div className={styles.rbox}>
                            <NavPosition history={history} match={match} location={location} menuList={menuList} updatePage={() => {
                                this.setState({ updateRandom: Math.random() });
                                this.mainContainerRef.current && (this.mainContainerRef.current.scrollTop = 0);
                            }} />
                            <div className={styles.routerstyle} ref={this.mainContainerRef}>
                                <Switch>
                                    {this._renderRoute(mRoutes)}
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    firstCardPath: state.home.firstCardPath, // 活动的一级导航路径
    secondCardPath: state.home.secondCardPath, // 活动的二级导航路径
    stretchOpen: state.home.stretchOpen, // 导航栏的伸展状态
    menuList: state.home.getMenuList,
});

Home.propTypes = {
    history: PropTypes.object, // 路由history对象
    location: PropTypes.object, //
    match: PropTypes.object, // 路由match对象
    routes: PropTypes.array, // 路由
    stretchOpen: PropTypes.bool, // 导航栏的伸展状态
    setStretchOpen: PropTypes.func, // 存储导航栏的伸展状态的函数
    getMenuList: PropTypes.func, // 获取该用户权限内相关菜单列表
    menuList: PropTypes.array, // 展示的菜单列表
    firstCardPath: PropTypes.string, // 活动的一级导航路径 
    secondCardPath: PropTypes.string, // 活动的二级导航路径
    setFirstCardPath: PropTypes.func, // 存储一级导航路径的函数
    setSecondCardPath: PropTypes.func, // 存储二级导航路径的函数
};

export default connect(mapStateToProps, AllActions)(Home);