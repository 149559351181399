import React from 'react';
import PropTypes from 'prop-types';
import { autoPageNum, autopageSize } from '@/assets/config';
import { setSessionStorage, getSessionStorage } from '@/assets/js/storage';
import moment from 'moment';

let contentCache = {};

class KeepAlive extends React.Component {

    static saveCache = (state) => {
        // 获取路由路径
        const href = window.location.href;
        const list = href.split('/');
        let cacheName = '';
        if (list[list.length - 1] == '') {
            cacheName = list[list.length - 3] + '/' + list[list.length - 2];
        } else {
            cacheName = list[list.length - 2] + '/' + list[list.length - 1];
        }
        // 生成要缓存的状态
        contentCache = { ...contentCache, ...state };
        // 缓存数据
        if (KeepAlive.prototype.tid) {
            let tidState = getSessionStorage(cacheName) ? JSON.parse(getSessionStorage(cacheName)) : {};
            let thisCache = contentCache;
            Object.keys(thisCache).forEach(function (key) {
                if (thisCache[key] && (thisCache[key] instanceof moment)) {
                    thisCache[key] = 'moment-' + moment(moment(thisCache[key]).format()).format('YYYY-MM-DD HH:mm:ss');
                }
            });
            tidState[KeepAlive.prototype.tid] = thisCache;
            setSessionStorage(cacheName, tidState);
        } else {
            let thisCache = contentCache;
            Object.keys(thisCache).forEach(function (key) {
                if (thisCache[key] && (thisCache[key] instanceof moment)) {
                    thisCache[key] = 'moment-' + moment(moment(thisCache[key]).format()).format('YYYY-MM-DD HH:mm:ss');
                }
            });
            setSessionStorage(cacheName, thisCache);
        }
    }

    static saveResetFunc = (func) => {
        // 应检测func是否包含pageNum和pageSize，若不包含则添加默认值，点击重置时若需发送请求，可以执行入参func方法
        if (typeof func === 'function') {
            func();
        }
    }

    cache = {};

    UNSAFE_componentWillMount() {
        contentCache = {};
        KeepAlive.prototype.tid = this.props.id;
        // 获取路由路径
        const href = window.location.href;
        const list = href.split('/');
        let cacheName = '';
        if (list[list.length - 1] == '') {
            cacheName = list[list.length - 3] + '/' + list[list.length - 2];
        } else {
            cacheName = list[list.length - 2] + '/' + list[list.length - 1];
        }
        // 检测缓存是否存在
        const cache = getSessionStorage(cacheName);
        if (cache) {
            contentCache = JSON.parse(getSessionStorage(cacheName));
            if (this.props.id) {
                contentCache = contentCache[KeepAlive.prototype.tid];
            }
        }
        // 当不存在缓存时，则赋值默认值
        if (contentCache == undefined || contentCache == null || contentCache == '' || Object.keys(contentCache).length == 0) {
            contentCache = { pageNum: autoPageNum, pageSize: autopageSize };
        }

        let thisCache = contentCache;
        Object.keys(thisCache).forEach(function (key) {
            if (thisCache[key] && thisCache[key].toString().substring(0, 7) == 'moment-') {
                thisCache[key] = moment(thisCache[key]);
            }
        });

        contentCache = thisCache;
    }

    componentDidMount() {
        this.props.render(contentCache);
    }

    render() {
        return (
            <div id='autoheight' style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%'
            }}>
                {this.props.children}
            </div>
        );
    }
}

KeepAlive.propTypes = {
    children: PropTypes.element,
    render: PropTypes.func,
    match: PropTypes.object,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

export default KeepAlive;