import React from 'react';
import PropTypes from 'prop-types';
import { Pagination, InputNumber } from 'antd';
import styles from './index.module.scss';
import { autopageSize } from '@/assets/config';

class PaginationModule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // resultTotal: props.resultTotal, // 总条数
            resultTotal: 300000, // 总条数(不设置分页，默认100000条)
            pageSize: props.pageSize, // 每页显示多少条
            pageNum: props.pageNum, // 当前第几页
            jumpPage: 1 // 跳到第几页
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        // this.setState({ resultTotal: newProps.resultTotal, pageSize: newProps.pageSize, pageNum: newProps.pageNum });
        this.setState({ pageSize: newProps.pageSize, pageNum: newProps.pageNum });
    }

    // 跳到第N页值改变时触发，此时还未请求新数据
    _jumpToChangeHandle = (value) => {
        if (!value) value = 1;
        this.setState({ jumpPage: value });
    }

    // 跳到第N页值失去焦点时触发，开始请求新数据
    _jumpBlurHandle = () => {
        this.setState({ pageNum: this.state.jumpPage });
        if (this.props.onChange) {
            this.props.onChange(this.state.pageSize, this.state.jumpPage);
        }
    }

    // 每页显示多少条输入框修改时回调，此时还未请求新数据直至输入框失去焦点
    _pageSizeChangeHandle = (value) => {
        if (!value) value = autopageSize;
        this.setState({ pageSize: value });
    }

    // 每页显示多少条输入框失去焦点回调（请求新数据）
    _pageSizeBlur = () => {
        if (this.props.onChange) {
            this.props.onChange(this.state.pageSize, this.state.pageNum);
        }
    }

    // 点击页面下标切换页面回调（请求新数据）
    _onPaginationChangeHandle = (value, pageSize) => {
        this.setState({ pageSize }, () => {
            if ((value * this.state.pageSize) >= this.state.resultTotal) {
                this.setState({ pageNum: value, resultTotal: parseInt(this.state.resultTotal + 100000) });
            } else {
                this.setState({ pageNum: value });
            }
            if (this.props.onChange) {
                this.props.onChange(this.state.pageSize, value);
            }
        });
    }
    componentDidMount() {
        if(this.props.simple) {
          document.querySelectorAll('.ant-pagination-item, .ant-pagination-item-ellipsis, .ant-pagination-jump-next, .ant-pagination-jump-prev ').forEach(el => {
            el.style.display = 'none';
          });
        }
    }

    componentDidUpdate(prevProps, prevState) {
       if(prevState.pageNum !== this.state.pageNum && this.props.simple) {
          document.querySelectorAll('.ant-pagination-item, .ant-pagination-item-ellipsis, .ant-pagination-jump-next, .ant-pagination-jump-prev ').forEach(el => {
            el.style.display = 'none';
          });
       }
    }
    render() {
        const { resultTotal, pageSize, pageNum, jumpPage } = this.state;
        const itemRender = (page, type, el) => {
            if (type === 'prev') {
                return <a>上一页</a>;
            }
            if (type === 'next') {
                return <a>下一页</a>;
            }
            return el;
        };
        return (
            <div className={styles.container}>
                {!this.props.simple && <div className={styles.pagecount}>
                    {/* 共
                    <div className={styles.total}>{resultTotal}</div>
                    条， */}
                    每页
                    <div className={styles.pagesize}>
                        <InputNumber min={1} max={100} value={pageSize} onChange={this._pageSizeChangeHandle} onBlur={this._pageSizeBlur} />
                    </div>
                    条
                </div>}
                <div className={styles.pageaction}>
                    {!this.props.simple && <div className={styles.pagejump}>
                        到第
                        <InputNumber min={1} max={10000000} value={jumpPage} onChange={this._jumpToChangeHandle} onBlur={this._jumpBlurHandle} />
                        页
                    </div>}
                    { this.props.simple ? <div className={styles.pagination}>
                        <Pagination itemRender={itemRender}  defaultCurrent={1} current={pageNum} total={resultTotal} pageSize={pageSize} onChange={this._onPaginationChangeHandle} />
                    </div> :
                    <div className={styles.pagination}>
                        <Pagination  defaultCurrent={1} current={pageNum} total={resultTotal} pageSize={pageSize} onChange={this._onPaginationChangeHandle} />
                    </div>}
                </div>
            </div>
        );
    }

}

PaginationModule.defaultProps = {
    resultTotal: 0,
    pageSize: autopageSize,
    pageNum: 1,
    simple: false
};

PaginationModule.propTypes = {
    simple: PropTypes.bool,
    resultTotal: PropTypes.number, // 总条数
    pageSize: PropTypes.number, // 每页显示多少条
    pageNum: PropTypes.number, // 当前第几页
    onChange: PropTypes.func, // 返回两个参数，'每页'+pageSize+'条，当前第'+pageNum+'页'
};

export default PaginationModule;