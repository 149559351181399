/**
 * 判断是否有值
 */
export function isEmpty(value) {
    return value === undefined || value === null || value === '';
}

/**
 * 手机号隐藏中间4位
 */
export function encryptedTelNumber(value) {
    const pattern = /^[1-9]\d{10}$/;
    if (value && value.toString().match(pattern)) {
        return value.toString().substr(0, 3) + '****' + value.toString().substr(7);
    }
}

/**
 * 姓名隐藏中间位
 */
export function encryptedName(value) {
    const name = value ? value.toString() : '';
    const len = name.length;
    if(len == 0){
        return value;
    }else if (len > 0 && len <= 2) {
        return name.substr(0, 1) + '*';
    } else {
        return name.substr(0, 1) + '*' + name.substr(len - 1);
    }
}

