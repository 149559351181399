import http from '@/assets/api/http';

export default {
    image: {
        // getToken: () => http('/qiniu/uploadImgToken'),
        // getFile: ({ key }) => http('/qiniu/getUrl/' + key),
        getToken: () => http('/?cmd=com.xqxc.api.QiniuUploadTokenService.qiNiuToken'),
        getFile: ({ key }) => http('/?cmd=com.xqxc.api.QiniuUploadTokenService.qiNiuGetUrl', {
            param: key
        }),
    },
    file: {
        // getToken: ({ fileName }) => http('/qiniu/uploadVideoToken'),
        // getFile: ({ fileName, key }) => http('/qiniu/getUrlAudioVideo/', { fileName: key })
        getToken: ({ fileName }) => http('/qiniu/uploadVideoToken'),
        getFile: ({ fileName, key }) => http('/qiniu/getUrlAudioVideo/', { fileName: key })
    }
};