import * as T from './actiontypes';
import { setLocalStorage, getLocalStorage } from '@/assets/js/storage';

/**
 * 存储用户验证信息
 */
const authInital = getLocalStorage('auth') ? JSON.parse(getLocalStorage('auth')) : { token: '', account: '', isAdmin: '', id: '', nick: '', avatar:'' };

export const auth = (state = authInital, { type, payload }) => {
    let newState = null;
    switch (type) {
        case T.SET_AUTH:
            newState = { ...payload };
            setLocalStorage('auth', newState);
            return newState;
        case T.INIT_AUTH:
            newState = { token: '', account: '', isAdmin: '', id: '', nick: '', avatar:'' };
            setLocalStorage('auth', newState);
            return newState;
        default:
            return state;
    }
};