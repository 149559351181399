import { combineReducers } from 'redux';
import auth from './auth';
import home from './home';
import account from './cmange/account';
import introductionlist from './cintroduction/initlist';
import enumGlobal from './enum';
import version from './csystem/version';

export default combineReducers({
    auth,
    home,
    account,
    introductionlist,
    enumGlobal,
    version,
});