import { createModel } from '@/store/tools';

const model = {
    namespace: 'version',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getVersionList': T('getVersionList'),
            'getVersionDetail': T('getVersionDetail'),
        },
        actions: {
            'getVersionList': A('getVersionList'), // 版本列表
            'getVersionDetail': A('getVersionDetail'), // 版本详情
        },
        sagas: {
            'getVersionList': S('getVersionList', '/?cmd=com.xqxc.api.pf.PfAppVersionManageService.listAppVersionManage'),
            'getVersionDetail': S('getVersionDetail', '/?cmd=com.xqxc.api.pf.PfAppVersionManageService.getAppVersion'),
        },
        reducers: {
            'getVersionList': R('getVersionList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getVersionDetail': R('getVersionDetail', {}),
        }
    })
};
export default createModel(model);