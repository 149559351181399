/*
重设密码
*/
import React, { PureComponent } from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Modal, message } from 'antd';
import { connect } from 'react-redux';
import http from '@/assets/api/http';
import model from '@/reducers/cmange/account/model';
import { XInputPassword } from '@/components/xqxc_ui';
import { LockOutlined } from '@ant-design/icons';


class ProducDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            visible: this.props.visible,
            confirmLoading: false,
            error: false
        };
    }
    componentDidMount() {
        const { id } = this.props.data;
        this.props.getKfAdmin({id});
    }

    _handleOk = () => {
        const { id } = this.props.data;
        const { pwd, newLoginPwdSure } = this.state;
        const reg = /^[0-9a-zA-Z!@#$%^&*(){}_|/'"\\[|\]]{6,16}$/; // 数字、字母、特殊字符
        if (!pwd) {
            message.error('新密码不为空！');
            return;
        } else if (!newLoginPwdSure) {
            message.error('确认密码不为空！');
            return;
        } else if (!reg.test(pwd)) {
            message.error('密码限制6-16位，允许输入数字、英文、常用符号（表情除外）');
            return;
        } else if (pwd != newLoginPwdSure) {
            this.setState({ error: true });
            message.error('两次输入的新密码不一致');
            return;
        } else {
            this.setState({ error: false });
            http('/?cmd=com.xqxc.api.kf.KFAdminService.resetKfAdminPwd', { id, pwd }, 'POST').then(() => {
                message.success('操作成功');
                this.setState({
                    visible: false,
                });
                this.props.closeMode(true);
            }).catch((e) => {
                message.error(e.message);
            });
        }
    }
    _handleCancel = () => {
        this.setState({
            visible: false,
        });
        this.props.closeMode();
    }
    _renderError = (error) => {
        if (error) {
            return (
                <div className={styles.error}>
                    <p style={{ color: 'red'}}>密码不一致!</p>
                </div>
            );
        }
    }
    _render = () => {
        const { pwd, newLoginPwdSure, error } = this.state;
        const { KfAdminInfo } = this.props;
        return <div className={styles.handle}>
            <div className={styles.rows}><div className={styles.label}>账号：</div><div style={{ marginLeft: 10 }}>{KfAdminInfo.account}</div></div>
            <div className={styles.rows}><div className={styles.label}>新密码：</div>
                <XInputPassword
                    inputStyle={{ width: '384px' }}
                    placeholder="输入新密码"
                    prefix={<LockOutlined />}
                    value={pwd}
                    bindThis={this}
                    bindName='pwd'
                    onChange={(value) => {
                        const reg = /^[0-9a-zA-Z!@#$%^&*(){}_|/'"\\[|\]]*$/; // 数字、字母、特殊字符
                        if (value && value.toString().length > 16) {
                            message.error('密码长度至多为16位');
                        } else if (!reg.test(value)) {
                            message.error('密码仅包括数字、英文、常用符号（表情除外）');
                        }
                    }}
                />
            </div>
            <div className={styles.rows}><div className={styles.label}>确认密码：</div>
                <XInputPassword 
                    inputStyle={{ width: '384px' }}
                    placeholder="确认新密码"
                    prefix={<LockOutlined />}
                    value={newLoginPwdSure}
                    bindThis={this}
                    bindName='newLoginPwdSure'
                />
            </div>
            {this._renderError(error)}
        </div>;
    }
    render() {
        const { visible, confirmLoading, title } = this.state;
        return (
            <Modal
                width={650}
                style={{ textAlign: 'center' }}
                centered
                title={title}
                visible={visible}
                confirmLoading={confirmLoading}
                onOk={this._handleOk}
                onCancel={this._handleCancel}
                destroyOnClose={true}
                keyboard={true}
                maskClosable={false}
                cancelText='取消'
                okText='确认'
            >
                {this._render()}
            </Modal>
        );
    }
}

ProducDetails.propTypes = {
    history: PropTypes.object, // 路由history
    match: PropTypes.object,// 路由history
    data: PropTypes.object, // 弹框入参
    visible: PropTypes.bool.isRequired, // 是否显示弹框
    closeMode: PropTypes.func.isRequired, // 关闭弹框的回调函数
    title: PropTypes.string,// 弹框标题
    getKfAdmin: PropTypes.func,
    KfAdminInfo: PropTypes.object
};

const mapStateToProps = (state) => ({
    KfAdminInfo: state.account.KfAdminInfo
});

export default connect(mapStateToProps, {...model.actions})(ProducDetails);