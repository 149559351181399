/**
 * 攻略管理
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton } from '@/components/xqxc_ui';
import { SearchBox, SearchItem } from '@/components/Layout';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import model from '@/reducers/cintroduction/initlist/model';
import enummodel from '@/reducers/enum/model';
import { autoPageNum, autopageSize } from '@/assets/config';
import { setBreadcrumbPaths } from '@/reducers/home/actions';
import { connect } from 'react-redux';
import http from '@/assets/api/http';

class Main extends React.Component {

    state = {
        title: '', // 标题
        appType: {}, // 使用位置
    };

    componentDidMount() {
        this.props.setBreadcrumbPaths(this.props.match); // 自动识别面包屑
        this.props.getAppTypeEnum();
    }

    // 查询
    _searchHandle = (useCache) => {
        const { title, appType, pageNum, pageSize } = this.state;
        const query = {
            title,
            useScene: appType.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        }
        this.props.getContentList(query);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            title: '',
            appType: {},
            pageNum: autoPageNum,
            pageSize: autopageSize,
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => { this._searchHandle('useCache'); });
    }

    // 表格操作
    _tableAction = (type, record) => {
        const { history } = this.props;
        const { id, state } = record;
        switch (type) {
            case 'edit':
                history.push({ pathname: `/home/cintroduction/initlist/edit/${id}` });
                break;
            case 'switch':
                http('/?cmd=com.xqxc.api.pf.PfStrategyService.updatePfStrategyState', { id, state: state == 0 ? 1 : 0 }, 'POST').then((response) => {
                    state == 1 ? message.warn('状态已关闭') : message.success('状态已开启');
                    this._searchHandle('useCache');
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
        }
    }

    render() {
        const { title, appType } = this.state;
        const { contentList, appTypeEnum } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XInput
                                label='文章标题'
                                placeholder='请输入文章标题'
                                value={title}
                                bindThis={this}
                                bindName='title'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='使用位置'
                                placeholder='请选择使用位置'
                                renderData={appTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={appType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='appType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton label='查询' onClick={this._searchHandle} />
                            <XCancelButton label='重置' onClick={this._resetHandle} />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        renderData={contentList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    setBreadcrumbPaths: PropTypes.func,
    getContentList: PropTypes.func,
    contentList: PropTypes.object,
    getAppTypeEnum: PropTypes.func,
    appTypeEnum: PropTypes.array,
};

const mapStateToProps = (state) => ({
    contentList: state.introductionlist.getContentList,
    appTypeEnum: state.enumGlobal.getAppTypeEnum,
});

export default connect(mapStateToProps, { setBreadcrumbPaths, ...model.actions, ...enummodel.actions })(Main);