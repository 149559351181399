import React, { useState } from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';
import { SortableElement, SortableContainer } from 'react-sortable-hoc';
import arrayMove from 'array-move';

const SortableItem = SortableElement(({ value }) => <li className='file-drag-sort-item'><div>拖拽排序</div></li>);

const SortableList = SortableContainer(({ items, className }) => {
    return (
        <ul className={classNames('files-drag-sort', className)} onClick={(e) => e.stopPropagation()}>
            {items.map((value, index) => (
                <SortableItem key={value.uid} index={index} value={value} />
            ))}
        </ul>
    );
});

const FileDragSort = ({ fileList = [], onDragEnd }) => {
    const [dragState, setDragState] = useState();
    function onSortEnd({ oldIndex, newIndex }) {
        oldIndex !== newIndex && onDragEnd && onDragEnd(arrayMove(fileList, oldIndex, newIndex));
        setDragState(undefined);
    }
    function onDragStart(state) {
        setDragState(state);
    }
    return (
        <SortableList
            axis='xy'
            className={dragState}
            helperClass='file-draging-sort-item'
            items={fileList}
            onSortStart={() => onDragStart('draging')}
            onSortEnd={onSortEnd}
        />
    );
};
FileDragSort.propTypes = {
    fileList: PropType.array,
    onDragEnd: PropType.func
};
export default FileDragSort;