/**
 * 用于antd表格项渲染相关
 */
import React from 'react';
import { Tooltip, Switch, Dropdown, Menu, InputNumber } from 'antd';
import styles from './index.module.scss';
import { regFenToYuan } from '@/util/money';
import { XMediaFn } from '@/components/xqxc_ui';
import { isEmpty } from '@/assets/js/utils';
import ButtonPerssion from '@/routes/ButtonPerssion';
import { isButtonPerssion } from '@/assets/js/buttonPerssion';
import { FloatConversion } from '@/util/conversion';
import { FormOutlined } from '@ant-design/icons';

export const tooltipStyle = () => {
    return {
        style: {
            maxWidth: 100,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        }
    };
};

const preStyles = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
};

const titleStyles = {
    display: 'inline-block',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-all'
};

const preStylesClick = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    color: '#37BB8F',
    cursor: 'pointer',
};

const preStylesClickLeft = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    color: '#37BB8F',
    cursor: 'pointer',
    textAlign: 'left',
    paddingLeft: '20px'
};

const preStylesLeft = {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    textAlign: 'left',
    paddingLeft: '20px'
};

/**
 * 文本项
 * value：文本内容
 * <pre/>预格式化标签，避免所有连续的空格或空行（换行）都会被显示为一个空格
 */
export const tooltip = (value, align) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={align == 'left' ? preStylesLeft : preStyles}>{isEmpty(value) ? '-' : value}</pre></Tooltip>;
};

/**
 * 文本项 - 可点击
 * value：文本内容
 * <pre/>预格式化标签，避免所有连续的空格或空行（换行）都会被显示为一个空格
 */
export const tooltipClick = (value, callback, align) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={align == 'left' ? preStylesClickLeft : preStylesClick} onClick={callback}>{isEmpty(value) ? '-' : value}</pre></Tooltip>;
};

/**
 * 带单位的文本项
 * value：文本内容
 */
export const tooltipWithUnit = (value, unit) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}>{value}</pre>}><pre style={preStyles}>{value}{unit}</pre></Tooltip>;
};

/**
 * 时间周期
 * startTime：开始时间
 * endTime：结束时间
 */
export const timePeriodCell = (startTime, endTime) => {
    return <Tooltip placement="top" title={<pre style={titleStyles}><div>{startTime}</div><div>{endTime}</div></pre>}>
        <pre style={preStyles}>
            <div>{startTime}</div>
            <div>{endTime}</div>
        </pre>
    </Tooltip>;
};

/**
 * 开关项
 * value：0关、1开
 */
export const switchCell = (value, callback) => {
    let checked = true;
    if (value == 0) {
        checked = false;
    }
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <Switch
                checkedChildren="开"
                unCheckedChildren="关"
                checked={checked}
                onChange={(value) => {
                    const result = value ? 1 : 0;
                    callback(result);
                }}
            />
        </div>
    );
};

/**
 * 金额(分转元)
 */
export const priceFenToYuanCell = (value) => {
    const price = regFenToYuan(value);
    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {price}
        </div>
    );
};

/**
 * 比率(千分转百分)
 */
export const rateCell = (value) => {
    const floatConversion = new FloatConversion();

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            {tooltip(floatConversion.divide(value, 10))}
        </div>
    );
};

/**
 * 图片项
 * @param {*} url
 */
export const ImgCell = (url) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '48px', height: '48px', marginRight: '0' }}
                dataType='image'
                dataSource={url}
                key={url}
            />
        </div>
    );
};

/**
 * 图片项
 * @param {*} url
 */
export const FileCell = (url, style) => {
    return (
        <div style={{ display: 'inline-flex' }}>
            <XMediaFn
                style={{ width: '48px', height: '48px', marginRight: '0', ...style }}
                dataSource={url}
                key={url}
            />
        </div>
    );
};

/**
 * 更多项
 * @param
 *  arr = [
            {key: 'MEMBER_TICKET', name: '卡券管理', onClick: () => tableAction(key, 'MEMBER_TICKET'), perssionCode: 'usermanage/member/kqgl' },
        ];
        perssionCode: 配置权限
 */
export const MoreCell = (arr = []) => {
    const cellItem = () => {
        let boolean = false;
        arr.map(i => {
            if ((i.perssionCode && isButtonPerssion(i.perssionCode)) || !i.perssionCode) {
                boolean = true;
            }
        });
        return boolean;
    };
    const _menu = (
        <Menu>
            {arr.map((i, index) => {
                return ((i.perssionCode && isButtonPerssion(i.perssionCode)) || !i.perssionCode) &&
                    <Menu.Item className={styles.moreStyles} key={i.key || index}>
                        {i.perssionCode ?
                            <ButtonPerssion code={i.perssionCode}>
                                <div onClick={i.onClick}>{i.name}</div>
                            </ButtonPerssion> :
                            <div onClick={i.onClick}>{i.name}</div>
                        }
                    </Menu.Item>;
            })}
        </Menu>
    );
    return (
        cellItem() && <Dropdown overlay={_menu} trigger={['click']} >
            <span style={{ cursor: 'pointer', color: '#37BB8F' }}>更多操作</span>
        </Dropdown>
    );
};

/** 排序列 */
export const sortCell = (value, callback, inputProps) => {
    // 表格项内容为可编辑
    const label = React.createRef();
    const box = React.createRef();
    const input = React.createRef();
    const icon = React.createRef();

    return (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
            <div className={styles.sort_box}>
                <div className={styles.label} ref={label}>{value}</div>
                <div className={styles.input} ref={box}>
                    <InputNumber
                        style={{ width: '70px' }}
                        size='small'
                        precision={0}
                        placeholder='请输入'
                        {...inputProps}
                        defaultValue={value}
                        ref={input}
                        onBlur={(e) => {
                            box.current.style.display = 'none';
                            label.current.style.display = 'inline-block';
                            icon.current.style.display = 'inline-block';
                            if (e.target.value && (value != e.target.value)) {
                                callback && callback(e.target.value);
                            }
                        }}
                    />
                </div>
                <div
                    ref={icon}
                    className={styles.icon}
                    onClick={() => {
                        label.current.style.display = 'none';
                        box.current.style.display = 'inline-block';
                        icon.current.style.display = 'none';
                        input.current.focus();
                    }}
                >
                    <FormOutlined />
                </div>
            </div>
        </div>
    );
};

/**
 * 表格宽度3中初始规范
 */
export const cellWidth = {
    small: 100,
    normal: 150,
    time: 180,
    big: 300
};