import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './NavPosition.module.scss';
import { Breadcrumb } from 'antd';
import { breadcrumbConfig } from '@/assets/config';
import * as AllActions from '@/reducers/home/actions';
import { setSessionStorage, getSessionStorage } from '@/assets/js/storage';

class NavPosition extends React.Component {

    pathname = '';
    breadcrumb = {};
    cachebreadcrumb = [];

    componentDidMount() {
        this.props.history.listen((route) => {
            if (route.pathname) {
                const pathArr = route.pathname.split('/');
                if (pathArr[1] == 'home' && pathArr.length == 3) { // 一级菜单
                    this.props.setFirstCardPath('/' + pathArr[1] + '/' + pathArr[2]);
                } else if (pathArr[1] == 'home' && pathArr.length == 4) {  // 二级菜单
                    this.props.setFirstCardPath('/' + pathArr[1] + '/' + pathArr[2]);
                    this.props.setSecondCardPath('/' + pathArr[1] + '/' + pathArr[2] + '/' + pathArr[3]);
                } else if (pathArr[1] == 'home' && pathArr.length > 4) { // 三级菜单
                    this.props.setFirstCardPath('/' + pathArr[1] + '/' + pathArr[2]);
                    this.props.setSecondCardPath('/' + pathArr[1] + '/' + pathArr[2] + '/' + pathArr[3]);
                    this.props.setThirdCardPath('/' + pathArr[1] + '/' + pathArr[2] + '/' + pathArr[3] + '/' + pathArr[4]);
                }
                this.props.updatePage(); // 刷新页面
            }
        });
    }

    _getBreadcrumbList = (breadcrumbPaths = null) => {
        if (!breadcrumbPaths) {
            return [];
        }
        this.pathname = breadcrumbPaths.url;
        const path = breadcrumbPaths.path ? breadcrumbPaths.path : '';
        const pathSplit = path ? path.split('/') : [];
        const moduleName = pathSplit[0] ? pathSplit[1] : pathSplit[2]; //  undefined
        const module = this._getCurrentModuleFromName(moduleName);
        if (!module) {
            return [];
        }
        const home = '/home';
        const allpath = home + module.path;
        let list = [{ title: module.title, path: allpath, href: allpath, jump: module.jump }];
        if (allpath != path) {
            if (Array.isArray(module.next) && module.next.length > 0) {
                const nextBreadcrumb = this._getNextArray(module.next, allpath);
                list = list.concat(nextBreadcrumb);
            }
        }

        if (Array.isArray(this.cachebreadcrumb)) {
            for (let i = 0; i < this.cachebreadcrumb.length; i++) {
                if (list[i] && this.cachebreadcrumb[i].path == list[i].path) {
                    list[i].href = this.cachebreadcrumb[i].href;
                }
            }
        }
        this.cachebreadcrumb = list;
        setSessionStorage('cachebreadcrumb', this.cachebreadcrumb);
        return list;
    }

    _getNextArray = (next, prevpath) => {
        let list = [];
        const { breadcrumbPaths } = this.props;
        const path = breadcrumbPaths.path ? breadcrumbPaths.path : '';
        const href = breadcrumbPaths.url ? breadcrumbPaths.url : '';
        for (let i = 0; i < next.length; i++) {
            if (prevpath + next[i].path == path) {
                list.push({ title: next[i].title, path, href, jump: next[i].jump });
                break;
            } else {
                if (next[i].next) {
                    const nextlist = this._getNextArray(next[i].next, prevpath);
                    if (nextlist.length > 0) {
                        list = [{ title: next[i].title, path: prevpath + next[i].path, href: prevpath + next[i].path, jump: next[i].jump }];
                        list = list.concat(nextlist);
                        break;
                    }
                }
            }
        }
        return list;
    }

    // 获取当前模块数据对象
    _getCurrentModuleFromName = (moduleName) => {
        let module = null;
        for (let i = 0; i < breadcrumbConfig.length; i++) {
            const path = breadcrumbConfig[i].path;
            if (path == '/' + moduleName) {
                module = breadcrumbConfig[i];
                break;
            }
        }
        return module;
    }

    _renderBreadcrumb = (list) => {
        return list.map((item) => {
            if (item.jump == false) {
                return <Breadcrumb.Item key={item.path} className={styles.breadcrumbitem}>{item.title}</Breadcrumb.Item>;
            } else {
                return <Breadcrumb.Item key={item.path} className={styles.breadcrumbitemhover} href={window.location.origin + '/#' + item.href}>{item.title}</Breadcrumb.Item>;
            }
        });
    }

    render() {
        const { breadcrumbPaths } = this.props;
        let breadcrumbList = [];
        this.cachebreadcrumb = getSessionStorage('cachebreadcrumb') && Array.isArray(JSON.parse(getSessionStorage('cachebreadcrumb'))) ? JSON.parse(getSessionStorage('cachebreadcrumb')) : [];
        breadcrumbList = this._getBreadcrumbList(breadcrumbPaths);
        return (
            <div className={styles.container}>
                {/* <div className={styles.left}>
                    <div className={styles.module_name}>欢迎使用商云客服后台管理系统！</div>
                </div> */}
                <div className={styles.right}>
                    {/* <img className={styles.icon} src={(require('@/assets/image/dingwei.png')).default} /> */}
                    <div className={styles.icon_label}>位置：</div>
                    <Breadcrumb separator='/'>
                        {this._renderBreadcrumb(breadcrumbList)}
                    </Breadcrumb>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    breadcrumbPaths: state.home.breadcrumbPaths
});

NavPosition.propTypes = {
    breadcrumbPaths: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    menuList: PropTypes.array,
    setBreadcrumbPaths: PropTypes.func,
    setFirstCardPath: PropTypes.func,
    setSecondCardPath: PropTypes.func,
    setThirdCardPath: PropTypes.func,
    updatePage: PropTypes.func,
};

export default connect(mapStateToProps, AllActions)(NavPosition);