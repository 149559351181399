import { createModel } from '@/store/tools';

const model = {
    namespace: 'introductionlist',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getContentList': T('getContentList'),
            'getContentDetail': T('getContentDetail'),
        },
        actions: {
            'getContentList': A('getContentList'), // 攻略列表
            'getContentDetail': A('getContentDetail'), // 攻略详情
        },
        sagas: {
            'getContentList': S('getContentList', '/?cmd=com.xqxc.api.pf.PfStrategyService.listPfStrategy'),
            'getContentDetail': S('getContentDetail', '/?cmd=com.xqxc.api.pf.PfStrategyService.getPfStrategy'),
        },
        reducers: {
            'getContentList': R('getContentList', { dataList: [], pageNum: 1, pageSize: 30, resultTotal: 0 }),
            'getContentDetail': R('getContentDetail', {}),
        }
    })
};
export default createModel(model);