/**
 * 账号管理
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message, DatePicker } from 'antd';
import { XInput, XDatePicker, XOKButton, XCancelButton, showConfirm, XInputNum } from '@/components/xqxc_ui';
import { SearchBox, SearchItem } from '@/components/Layout';
import KeepAlive from '@/routes/KeepAlive';
import http from '@/assets/api/http';
import TableContent from './components/TableContent';
import Password from './modal/password';
import model from '@/reducers/cmange/account/model';
import { autoPageNum, autopageSize } from '@/assets/config';
import { setBreadcrumbPaths } from '@/reducers/home/actions';
import moment from 'moment';
import { connect } from 'react-redux';

class Main extends React.Component {

    state = {
        id: '',
        account: '',
        startTime: null,
        endTime: null,
        startTimeKey: Date.now() + 5,
        endTimeKey: Date.now() + 10
    };

    componentDidMount() {
        this.props.setBreadcrumbPaths(this.props.match); // 自动识别面包屑
    }

    _searchHandle = (useCache) => {
        const { id, account, startTime, endTime, pageNum, pageSize } = this.state;
        const query = {
            id,
            account,
            startTime: startTime != null ? startTime.format('YYYY-MM-DD HH:mm:ss') : null,
            endTime: endTime != null ? endTime.format('YYYY-MM-DD HH:mm:ss') : null,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
            pageSize: useCache == 'useCache' ? pageSize : autopageSize, // 【第2步，当有useCache时使用当前分页状态，否则使用默认分页状态】
        };
        KeepAlive.saveCache({ ...this.state, pageNum: query.pageNum, pageSize: query.pageSize }); // 【第3步，缓存查询状态】
        this.props.listKfAdmin(query);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            id: '',
            account: '',
            startTime: null,
            endTime: null,
            pageNum: 1,
            pageSize: 30,
        }, () => {
            KeepAlive.saveResetFunc(this._searchHandle); // 【第4步：将是否调用重置交给KeepAlive控制】
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => {
            this._searchHandle('useCache');
        });
    }

    _newHandle = () => {
        this.props.history.push({
            pathname: '/home/cmanage/account/create',
        });
    }

    _tableAction = (id, type) => {
        const { history } = this.props;
        switch (type) {
            case 'edit':
                history.push({
                    pathname: `/home/cmanage/account/edit/${id}`,
                });
                break;
            case 'pwd':
                this.setState({ openModeParam: id, currentOpenMode: 'pwd' });
                break;
            case 'del':
                showConfirm('删除确认', '客服信息删除后无法恢复，确定删除此客服信息吗？', () => {
                    http('/?cmd=com.xqxc.api.kf.KFAdminService.delKfAdminPwd', { ids: [id] }, 'POST')
                        .then(() => {
                            this._searchHandle('useCache');
                            message.success('删除成功。');
                        }).catch((reject = {}) => {
                            message.error(reject.message);
                        });
                });
                break;
        }
    }

    //打开弹窗
    _renderMode = () => {
        switch (this.state.currentOpenMode) {
            case 'pwd':
                return <Password title='修改密码' data={{ id: this.state.openModeParam }} visible={true} closeMode={this._closeMode} />;
            default:
                return null;
        }
    }

    // 关闭弹窗
    _closeMode = (flag) => {
        this.setState({ currentOpenMode: '' });
        if (flag) {
            this._searchHandle('useCache');
        }
    }

    render() {
        const { id, account, startTime, endTime } = this.state;
        const { listKfAdminList } = this.props;
        return (
            <KeepAlive id='1' render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache'); // 【第1步：调用查询接口初始化数据】
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <div className={styles.table_body}>
                        <SearchBox>
                            <SearchItem>
                                <XInputNum
                                    label='编号'
                                    placeholder='请输入编号'
                                    value={id}
                                    bindThis={this}
                                    bindName='id'
                                    min={0}
                                    precision={0}
                                />
                            </SearchItem>
                            <SearchItem>
                                <XInput
                                    label='客服账号'
                                    placeholder='请输入客服账号'
                                    value={account}
                                    bindThis={this}
                                    bindName='account'
                                />
                            </SearchItem>
                            <SearchItem>
                                <label style={{ marginRight: '10px' }}>更新时间</label>
                                <DatePicker value={startTime} style={{ margin: '5px 15px 0 0', width: '160px', height: '30px' }} showTime
                                    onChange={(e) => {
                                        if (!e) {
                                            this.setState({
                                                startTime: null
                                            });
                                            return false;
                                        }

                                        let { endTime } = this.state;
                                        this.setState({
                                            startTime: moment(e._d),
                                        });
                                        if (endTime && (new Date(moment(e._d))).getTime() > new Date(endTime._d).getTime()) {
                                            this.setState({
                                                startTime: null,
                                                //startTimeKey: Date.now()
                                            });
                                            message.warn('开始时间不能大于结束时间');
                                        }
                                    }} />
                                <DatePicker value={endTime} style={{ margin: '5px 15px 0 0', width: '160px', height: '30px' }} showTime onChange={(e) => {
                                    if (!e) {
                                        this.setState({
                                            endTime: null
                                        });
                                        return false;
                                    }
                                    let { startTime } = this.state;
                                    this.setState({
                                        endTime: moment(e._d)
                                    });


                                    if (startTime && (new Date(moment(e._d))).getTime() < new Date(startTime._d).getTime()) {
                                        this.setState({
                                            endTime: null,
                                            //endTimeKey: Date.now()
                                        });
                                        message.warn('结束时间不能小于开始时间');
                                    }
                                }} />
                            </SearchItem>
                            <SearchItem>
                                <XOKButton
                                    label='查询'
                                    onClick={this._searchHandle}
                                />
                                <XCancelButton
                                    label='重置'
                                    onClick={this._resetHandle}
                                />
                            </SearchItem>
                        </SearchBox>
                        <XOKButton style={{ width: 90 }} label='新建账号' onClick={this._newHandle} />
                    </div>
                    <TableContent
                        renderData={listKfAdminList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                    {this._renderMode()}
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    setBreadcrumbPaths: PropTypes.func,
    listKfAdmin: PropTypes.func,
    listKfAdminList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    listKfAdminList: state.account.listKfAdminList
});

export default connect(mapStateToProps, { setBreadcrumbPaths, ...model.actions })(Main);