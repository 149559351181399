import React from 'react';
import styles from './TableContent.module.scss';
import { XPagination } from '@/components/xqxc_ui';
import { Table } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { tooltipStyle, tooltip, cellWidth, switchCell } from '@/components/TableCell';

class TableContent extends React.Component {

    _takeColumns = [
        {
            title: '文章标题',
            dataIndex: 'title',
            key: 'title',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '使用位置',
            dataIndex: 'useSceneName',
            key: 'useSceneName',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '状态',
            dataIndex: 'state',
            key: 'state',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: (text, record) => switchCell(text, value => this.props.tableAction('switch', record))
        },
        {
            title: '创建时间',
            dataIndex: 'gmtCreate',
            key: 'gmtCreate',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '修改人',
            dataIndex: 'modifyUserName',
            key: 'modifyUserName',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '修改时间',
            dataIndex: 'gmtModified',
            key: 'gmtModified',
            width: cellWidth.normal,
            onCell: tooltipStyle,
            render: tooltip
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'id',
            width: cellWidth.normal,
            render: (key, record) => this._renderActionCell(record)
        }
    ];

    _takeDataSource = (dataList) => {
        if (Array.isArray(dataList) && dataList.length > 0) {
            return dataList;
        } else {
            return [];
        }
    }

    _renderActionCell = (record) => {
        const { tableAction } = this.props;
        return (
            <div className={styles.action}>
                <div className={styles.item} onClick={() => tableAction('edit', record)}>编辑</div>
            </div>
        );
    };

    render() {
        const { paginationChange } = this.props;
        const { pageNum, pageSize, resultTotal, dataList } = this.props.renderData;
        return (
            <div className={styles.tableContainer}>
                <Table
                    rowClassName={(record, index) => index % 2 === 0 ? '' : styles.tdcolor}
                    rowKey={record => record.id}
                    columns={this._takeColumns}
                    dataSource={this._takeDataSource(dataList)}
                    pagination={false}
                    scroll={{ x: 'max-content', y: true }}
                />
                <XPagination
                    resultTotal={resultTotal}
                    pageSize={pageSize}
                    pageNum={pageNum}
                    onChange={paginationChange}
                />
            </div>
        );
    }
}

TableContent.propTypes = {
    renderData: PropTypes.object.isRequired, // 表格数据源
    tableAction: PropTypes.func.isRequired, // 表格操作
    paginationChange: PropTypes.func.isRequired, // 分页操作
    scrollY: PropTypes.string,
};

export default TableContent;
