import React, { useState } from 'react';
import PropType from 'prop-types';
import CustomUpload from './CustomUpload';
import { nonEmptyCall } from '@/util/utils';
// 包一层更新上传状态，解决没有有antd上传时的状态不更新问题
function CustomUploadWraper(props) {
    const { onFileListChange, useQiniu } = props;
    const [uploading, setUploading] = useState();
    return React.createElement(CustomUpload, Object.assign({}, props, {
        onFileListChange: (info, file) => {
            nonEmptyCall(onFileListChange, info, file);
            if (file && useQiniu !== false) {
                if (file.status === 'uploading') {
                    setUploading(true);
                }
                if (file.status === 'success' || file.status === 'error') {
                    setTimeout(() => setUploading(false), 100);
                }
            }
            else {
                setUploading(false);
            }
        }
    }));
}
CustomUploadWraper.propTypes = {
    onFileListChange: PropType.func,
    useQiniu: PropType.object
};
let UploadElement = (props, ref) => <CustomUploadWraper {...props} forwardedRef={ref} />;
export default React.forwardRef(UploadElement);