/**
 * 信息组件（标签：内容，如 姓名：张三）
 * <XInfoLabel label="物流体积：" value={'数值'} />
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

class InfoLabel extends React.Component {

    isEmpty(value) {
        return value === undefined || value === null || value === '';
    }

    render() {
        const { style, label, value } = this.props;

        return (
            <div className={styles.container} style={style}>
                <div className={styles.label}>{label}</div>
                <Tooltip title={value} className={styles.value}>
                    <span>{!this.isEmpty(value) ? value : '-'}</span>
                </Tooltip>
            </div>
        );
    }
}

InfoLabel.propTypes = {
    style: PropTypes.object, // 总样式
    label: PropTypes.string, // 标题值
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // 内容值，为字符串或数字
};

export default InfoLabel;