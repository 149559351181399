import { createModel } from '@/store/tools';

const model = {
    namespace: 'enumGlobal',
    build: (T, A, S, R, C) => ({
        actiontypes: {
            'getAppTypeEnum': T('getAppTypeEnum'),
            'getStateEnum': T('getStateEnum'),
            'getUpdateModeEnum': T('getUpdateModeEnum'),
            'getAppSystemTypeEnum': T('getAppSystemTypeEnum'),
        },
        actions: {
            'getAppTypeEnum': A('getAppTypeEnum', { enumName: 'AppTypeEnum' }),
            'getStateEnum': A('getStateEnum', { enumName: 'StateEnum' }),
            'getUpdateModeEnum': A('getUpdateModeEnum', { enumName: 'UpdateModeEnum' }),
            'getAppSystemTypeEnum': A('getAppSystemTypeEnum', { enumName: 'AppSystemTypeEnum' }),
        },
        sagas: {
            'getAppTypeEnum': S('getAppTypeEnum', '/?cmd=com.xqxc.api.EnumService.getEnum'),
            'getStateEnum': S('getStateEnum', '/?cmd=com.xqxc.api.EnumService.getEnum'),
            'getUpdateModeEnum': S('getUpdateModeEnum', '/?cmd=com.xqxc.api.EnumService.getEnum'),
            'getAppSystemTypeEnum': S('getAppSystemTypeEnum', '/?cmd=com.xqxc.api.EnumService.getEnum'),
        },
        reducers: {
            'getAppTypeEnum': R('getAppTypeEnum', []),
            'getStateEnum': R('getStateEnum', []),
            'getUpdateModeEnum': R('getUpdateModeEnum', []),
            'getAppSystemTypeEnum': R('getAppSystemTypeEnum', []),
        }
    })
};
export default createModel(model);