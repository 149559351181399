/**
 *  主页 - 欢迎页
 *  npm install ant-design-pro@latest --save
 * */
 import React from 'react';
 import Background from '../../../assets/image/dance_bg.png';
 
 class Welcome extends React.Component {
     render() {
         return null;
         // return (
         //     <div style={{ backgroundImage: `url(${Background})`, width: '100%', height: '100%', backgroundSize: 'cover'}}></div>
         // );
     }
 }
 export default Welcome;