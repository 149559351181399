import React, { useState } from 'react';
import PropType from 'prop-types';
import classNames from 'classnames';
import { Upload, message, Modal, Empty } from 'antd';
import useQiniuUpload from './QiniuUpload';
import { nonEmptyCall } from '@/util/utils';
import { getAudioDuration } from './_util';
import FileDragSort from './FileDragSort';
export default function CustomUpload(props) {
    const { wallMode, removeError, qiniuUploadProps, useQiniu, returnAudioDuration, className, onFileListChange, onChange, children, startSelectCard, hiddenDisabled, forwardedRef,
        // 拖拽功能
        startDragSort, onDragSortChange,
        // 预览功能
        startPreviewImage, previewModalProps, previewEmptyProps, ...otherProps } = props;
    const [singleHidden, setSingleHidden] = useState(wallMode === 'single' && otherProps.listType === 'picture-card' && (props.fileList || props.defaultFileList || []).length > 0);
    const [previewImage, setPreviewImage] = useState();
    const [previewVisible, setPreviewVisible] = useState(false);
    if (wallMode === 'single') {
        let tempList = (props.fileList || props.defaultFileList || []).filter((f) => f.status !== 'removed' && f.status !== 'error');
        if (!singleHidden && tempList.length > 0) {
            setSingleHidden(true);
        }
        if (singleHidden && tempList.length === 0) {
            setSingleHidden(false);
        }
        if (Array.isArray(props.fileList) && props.fileList.length !== tempList.length) {
            props.fileList.splice(0, props.fileList.length);
            props.fileList.push(...tempList);
        }
    }
    const [percent, setPercent] = useState(0);
    let customFileInfo;
    let customFile;
    const { qiniuUpload } = useQiniuUpload(qiniuUploadProps, {
        next(res) {
            if (customFile) {
                customFile.status = 'uploading';
                customFile.percent = res.total.percent;
                customFile.response = res;
                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                setPercent(customFile.percent);
            }
        },
        error(res) {
            if (customFile) {
                customFile.status = 'error';
                customFile.percent = 0;
                customFile.response = res;
                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                setPercent(customFile.percent);
            }
        },
        complete(res) {
            if (customFile) {
                customFile.status = 'done';
                customFile.size = res.size;
                customFile.percent = 100;
                customFile.response = res;
                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                setPercent(customFile.percent);
            }
        }
    });
    //添加取消上传功能
    function commonChangeHandle(info) {
        if (removeError !== false)
            info.fileList = info.fileList.filter(f => f.status !== 'error');
        if (wallMode === 'single' && otherProps.listType === 'picture-card') {
            setSingleHidden(info.fileList.length > 0);
        }
        nonEmptyCall(onChange, info);
        nonEmptyCall(onFileListChange, info, customFile);
    }
    function onCustomChange(info) {
        customFileInfo = info;
        customFile = undefined;
        if (info.file.status !== 'removed') {
            customFileInfo.fileList.forEach((f) => {
                if (f.uid === customFileInfo.file.uid) {
                    customFile = f;
                }
            });
        }
        commonChangeHandle(info);
    }
    function uploadCatch(error) {
        console.log(error);
        message.error('文件上传异常，请重新上传');
        if (customFile) {
            customFile.status = 'error';
            customFile.response = '上传异常';
            commonChangeHandle(customFileInfo);
        }
    }
    function customBeforeUpload(file, fileList) {
        if (file.name.trim().split(/.+ +.+/).length > 1) {
            message.warning('文件名含有空字符串，可能导致上传异常');
        }
        const isUpload = nonEmptyCall(props.beforeUpload, file, fileList);
        if (useQiniu !== false && isUpload !== false) {
            new Promise((resolve, reject) => qiniuUpload(file, resolve, reject).catch((error) => uploadCatch(error))).then(({ fileUrl }) => {
                if (customFile) {
                    customFile.status = 'success';
                    customFile.url = fileUrl;
                    if (returnAudioDuration && file.type.startsWith('audio')) {
                        getAudioDuration(file).then((duration) => {
                            if (customFile) {
                                customFile.duration = duration;
                                nonEmptyCall(onFileListChange, customFileInfo, customFile);
                            }
                        });
                    }
                    else {
                        nonEmptyCall(onFileListChange, customFileInfo, customFile);
                    }
                }
            }).catch((error) => uploadCatch(error));
            return false;
        }
        return isUpload;
    }

    let previewProps = startPreviewImage
        ? {
            onPreview: (file) => {
                setPreviewImage(file.url);
                setPreviewVisible(true);
            }
        }
        : undefined;

    return <>
        <Upload
            ref={forwardedRef}
            {...previewProps}
            {...otherProps}
            beforeUpload={customBeforeUpload}
            onChange={onCustomChange}
            className={classNames('lz-custom-upload', className, wallMode, { singleHidden, startSelectCard, hiddenDisabled })}
        >
            {children}
            {startDragSort ? <FileDragSort fileList={(props.fileList || props.defaultFileList)} onDragEnd={onDragSortChange} listType={otherProps.listType} /> : null}
            <span style={{ display: 'none' }}>{percent}</span>
        </Upload>
        {startPreviewImage &&
            <Modal
                width='max-content'
                visible={previewVisible}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
                {...previewModalProps}
            >
                {previewImage
                    ? <img style={{ width: '100%' }} src={previewImage} />
                    : <Empty description='无预览图片' {...previewEmptyProps} />}
            </Modal>
        }
    </>;
}
CustomUpload.propTypes = {
    forwardedRef: PropType.func,
    children: PropType.object,

    /** 是否自动移除错误图片 */
    removeError: PropType.bool,
    fileList: PropType.array,
    defaultFileList: PropType.array,
    beforeUpload: PropType.func,
    /** 照片墙模式，single 时为单张，listType==='picture-card' 有效 */
    wallMode: PropType.string,
    /** 是否开启选中图片 */
    startSelectCard: PropType.bool,
    /** 七牛上传配置 */
    qiniuUploadProps: PropType.object,
    /** 是否用七牛上传，默认是 */
    useQiniu: PropType.object,
    /** 上传媒体文件时，是否返回文件时长 */
    returnAudioDuration: PropType.bool,
    className: PropType.string,
    onFileListChange: PropType.func,
    onChange: PropType.func,
    /** 是否开启拖拽 */
    startDragSort: PropType.bool,
    /** 拖拽后的监听 */
    onDragSortChange: PropType.func,
    /** 是否开启图片预览 */
    startPreviewImage: PropType.bool,
    /** 图片预览弹框参数 */
    previewModalProps: PropType.object,
    /** 图片预览空占位参数 */
    previewEmptyProps: PropType.object,
    /** 禁用时是否隐藏上传按钮 */
    hiddenDisabled: PropType.bool
};