import * as T from './actiontypes';
import { setLocalStorage, getLocalStorage } from '@/assets/js/storage';

/**
 * 存储面包屑导航路径 
 * @param {Array} state  
 */
let breadcrumbPathsInital = getLocalStorage('breadcrumbPaths') ? JSON.parse(getLocalStorage('breadcrumbPaths')).data : {};

export const breadcrumbPaths = (state = breadcrumbPathsInital, action) => {
    switch (action.type) {
        case T.SET_BREADCRUMB_PATHS: // 设置面包屑路径
            setLocalStorage('breadcrumbPaths', { data: action.payload });
            return action.payload;
        case T.CLEAN_BREADCRUMB_PATHS: // 清理面包屑路径
            return {};
        default:
            return state;
    }
};

/**
 * 存储当前活动的一级导航路径
 */
let firstCardPathInital = getLocalStorage('firstCardPath');

export const firstCardPath = (state = firstCardPathInital, action) => {
    switch (action.type) {
        case T.SET_FIRST_CARD_PATH:
            setLocalStorage('firstCardPath', action.payload);
            return action.payload;
        default:
            return state;
    }
};

/**
 * 存储当前活动的二级导航路径
 */
let secondCardPathInital = getLocalStorage('secondCardPath');

export const secondCardPath = (state = secondCardPathInital, action) => {
    switch (action.type) {
        case T.SET_SECOND_CARD_PATH:
            setLocalStorage('secondCardPath', action.payload);
            return action.payload;
        default:
            return state;
    }
};

/**
 * 存储当前活动的三级导航路径
 */
let thirdCardPathInital = getLocalStorage('thirdCardPath');

export const thirdCardPath = (state = thirdCardPathInital, action) => {
    switch (action.type) {
        case T.SET_THIRD_CARD_PATH:
            setLocalStorage('thirdCardPath', action.payload);
            return action.payload;
        default:
            return state;
    }
};

/**
 * 存储当前导航栏的伸展状态
 * @param {Boolean} state false关闭；true打开
 */
export const stretchOpen = (state = true, action) => {
    switch (action.type) {
        case T.SET_STRETCHOPEN:
            return action.payload;
        default:
            return state;
    }
};

/**
 * 获取登录时菜单
 */
export const getMenuList = (state = [], action) => {
    switch (action.type) {
        case T.SET_MENUS_LIST:
            return action.payload.result;
        default:
            return state;
    }
};