/**
 * 工作量统计
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Card, Tabs, Table, Radio, DatePicker, Tooltip, Menu, Dropdown } from 'antd';
import numeral from 'numeral';
import { XInput, XInputNum, XSelect, XOKButton, XCancelButton} from '@/components/xqxc_ui';
import { ChartCard, yuan, MiniArea, MiniBar, MiniProgress, Field, Bar, Pie, TimelineChart } from 'ant-design-pro/lib/Charts';
import Trend from 'ant-design-pro/lib/Trend';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import { getTimeDistance } from '../utils/utils';
import { connect } from 'react-redux';
import { SearchBox, SearchItem } from '@/components/Layout';
import { setBreadcrumbPaths } from '@/reducers/home/actions';

import styles from './index.module.scss';


const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const tempData = { 
    'offlineChartData': [{ 'x': 1571146783163, 'y1': 5, 'y2': 24 }, { 'x': 1571148583163, 'y1': 39, 'y2': 75 }, { 'x': 1571150383163, 'y1': 41, 'y2': 52 }, { 'x': 1571152183163, 'y1': 83, 'y2': 45 }, { 'x': 1571153983163, 'y1': 32, 'y2': 43 }, { 'x': 1571155783163, 'y1': 31, 'y2': 109 }, { 'x': 1571157583163, 'y1': 64, 'y2': 63 }, { 'x': 1571159383163, 'y1': 32, 'y2': 49 }, { 'x': 1571161183163, 'y1': 84, 'y2': 101 }, { 'x': 1571162983163, 'y1': 24, 'y2': 17 }, { 'x': 1571164783163, 'y1': 102, 'y2': 26 }, { 'x': 1571166583163, 'y1': 43, 'y2': 91 }, { 'x': 1571168383163, 'y1': 44, 'y2': 37 }, { 'x': 1571170183163, 'y1': 37, 'y2': 72 }, { 'x': 1571171983163, 'y1': 109, 'y2': 37 }, { 'x': 1571173783163, 'y1': 28, 'y2': 42 }, { 'x': 1571175583163, 'y1': 60, 'y2': 71 }, { 'x': 1571177383163, 'y1': 69, 'y2': 103 }, { 'x': 1571179183163, 'y1': 20, 'y2': 103 }, { 'x': 1571180983163, 'y1': 91, 'y2': 20 }], 

    salesTypeData: [{ 'x': '好评', 'y': 100 }, { 'x': '中评', 'y': 3321 }, { 'x': '差评', 'y': 3113 }], 
    salesData: [{ 'x': '0-10', 'y': 1083 }, { 'x': '10-20', 'y': 1086 }, { 'x': '20-30', 'y': 694 }, { 'x': '30-40', 'y': 204 }, { 'x': '40以上', 'y': 888 }], 
};

class Main extends React.Component {
    state = {
        loading: false,
        salesType: 'all',
        currentTabKey: '',
        rangePickerValue: [],
        tempData: {},
    }

    mapContainer = React.createRef(null)

    componentDidMount() {
        this.props.setBreadcrumbPaths(this.props.match); // 自动识别面包屑
        import('./china.js').then(() => {
            this.loadMap();
         });
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.resizeListener, !0);
    }
    handleChangeSalesType = (e) => {
        this.setState({
            salesType: e.target.value,
        });
    }

    handleTabChange = (key) => {
        this.setState({
            currentTabKey: key,
        });
    }

    handleRangePickerChange = (rangePickerValue) => {
        this.setState({
            rangePickerValue,
        });

        // this.props.dispatch({
        //   type: 'chart/fetchSalesData',
        // });
    }

    selectDate = (type) => {
        this.setState({
            rangePickerValue: getTimeDistance(type),
        });

        // this.props.dispatch({
        //   type: 'chart/fetchSalesData',
        // });
    }

    isActive(type) {
        const { rangePickerValue } = this.state;
        const value = getTimeDistance(type);
        if (!rangePickerValue[0] || !rangePickerValue[1]) {
            return;
        }
        if (rangePickerValue[0].isSame(value[0], 'day') && rangePickerValue[1].isSame(value[1], 'day')) {
            return styles.currentDate;
        }
    }

    loadMap = () => {
    var optionMap = {  
      backgroundColor: 'transparent',  
      title: {  
        // text: '全国地图大数据',  
        subtext: '',  
        x:'center'  
      },  
      tooltip: { 
        trigger:'item',
        formatter: function (params) { 
          if(params.data) {
            let info = `<p style="font-size:18px">${params.name}</p><p style="font-size:14px">${params.data.nick}<br>${params.data.total}</p>`;
            return info; 
          }  
          return null;
        }, 
        backgroundColor: '#4d4d4d', // 提示标签背景颜色 
        textStyle: { color: '#fff' } // 提示标签字体颜色 
      },   

      series: [{  
        type: 'map',  
        mapType: 'china',   
        roam: false,  
        scaleLimit: { // 滚轮缩放的极限控制
          min: 1.2,
          max: 2
        },
        itemStyle: {
          normal: {
            areaColor: '#d3e5ff',
            borderWidth:1,
            borderColor:'#fff'
          },
          emphasis: {  
            label: {
              show: true

            },
            areaColor: '#1890ff'
          } 
        },
        label: {  
          normal: {  
            show: true, // 省份名称  
            textStyle: {
              color:'#555'
            },
            areaStyle: {
              color: 'red'// 默认的地图板块颜色
            }
          },  
          
          emphasis: {  
            show: false,
            areaColor: '#ffffff'
          }  
        },  
  
        data:[{ name: '北京', value:'YES', total:234242342, 'nick': 'jamesanthony' }, { name: '天津' }, { name: '上海' }, { name: '重庆' }, { name: '河北' }, { name: '河南' }, { name: '云南' }, { name: '辽宁' }, { name: '黑龙江' }, { name: '湖南' }, { name: '安徽' }, { name: '山东' }, 
          { name: '新疆' }, { name: '江苏' }, { name: '浙江' }, { name: '江西' }, { name: '湖北' }, { name: '广西' }, { name: '甘肃' }, { name: '山西' }, { name: '内蒙古' }, { name: '陕西' }, { name: '吉林' }, { name: '福建' }, { name: '贵州' }, 
          { name: '广东', value:'YES', total:22222, 'nick': 'lebroncarmelo' }, { name: '青海' }, { name: '西藏' }, { name: '四川' }, { name: '宁夏' }, { name: '海南' }, { name: '台湾' }, { name: '香港' }, { name: '澳门' }] // 数据
      }]  
    }; 
    this.myChart = window.echarts.init(this.mapContainer.current);
    this.myChart.setOption(optionMap);
    window.addEventListener('resize', this.resizeListener = () => {
      this.myChart.resize();
    }, !0);

    this.myChart.on('mouseover', (params) => { 
      if(params.data === undefined) {
        this.myChart.dispatchAction({
          type: 'restore'
        });
      }
    });
  }
    render() {
        return (
            <>
            <Row>
            <SearchBox>
                <SearchItem>
                <RangePicker
                    value={this.state.rangePickerValue}
                    onChange={this.handleRangePickerChange}
                    style={{ width: 256}}
                />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        style={{marginRight:0, marginTop:'-4px'}}
                        placeholder='请选择'
                        renderData={[]}
                        dataIndex='areaName'
                        keyIndex='id'
                        value="全部商户"
                        bindThis={this}
                        bindName='cityItem'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        style={{marginRight:0, marginTop:'-4px'}}
                        placeholder='请选择'
                        renderData={[]}
                        dataIndex='areaName'
                        keyIndex='id'
                        value="全部店铺"
                        bindThis={this}
                        bindName='cityItem'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                    <XSelect
                        style={{ marginTop:'-4px'}}
                        placeholder='请选择'
                        renderData={[]}
                        dataIndex='areaName'
                        keyIndex='id'
                        value="全部客服"
                        bindThis={this}
                        bindName='cityItem'
                        showSearch={true}
                    />
                </SearchItem>
                <SearchItem>
                 <XOKButton label='查询' style={{marginLeft:'10px', marginTop:'-4px'}}/>
                </SearchItem>
            </SearchBox> 
            </Row>
           <Row style={{marginTop:'15px'}}>
              <div className={styles.statsPanel}>
               <div>

                   <p>总会话量</p>
                   <p>230</p>
                   <p>有效会话数200</p>
               </div>
               <div>
                   <p>总客户量</p>
                   <p>39</p>
                   <p>转接后平均响应时长40s</p>
               </div>
               <div>
                   <p>总会话消息数</p>
                   <p>2999</p>
               </div>
               <div>
                   <p>平均会话时长</p>
                   <p>9m3s</p>
                   <p>平均响应时间 30s</p>
               </div>
               <div>
                   <p>好评率</p>
                   <p>78%</p>
                   <p>评价率 67%</p>
               </div>
               <div>
                   <p>平均每会话消息数</p>
                   <p>199</p>
               </div>
               
              </div>
           </Row>
           <div style={{height:'480px', margin:'40px 0'}}>
            <h4 style={{marginBottom: '20px' }}>会话数</h4>
            <div className={styles.timelineCharts} >
                <TimelineChart
                    data={tempData.offlineChartData}
                    titleMap={{ y1: '总访问量', y2: '总会话量' }}
                />
            </div>
                
            </div>
           <Row justify="space-between" style={{marginTop:'100px'}}>
                <Col span={10}>
                <h4 style={{ marginTop: 8, marginBottom: '20px' }}>客户来源占比</h4>
                <Pie
                    hasLegend
                    data={tempData.salesTypeData}
                    height={200}
                    lineWidth={1}
                />
                </Col>
                <Col span={10}>
                <h4 style={{ marginTop: 8, marginBottom: '20px' }}>评价占比</h4>
                <Pie
                    hasLegend
                    data={tempData.salesTypeData}
                    height={200}
                    lineWidth={1}
                />
                </Col>
            </Row>

           <Row>
             <h4 style={{ display:'none', marginTop: 8, marginBottom: '20px' }}>区域分布</h4>
             <div id="maps" style={{display:'none', height:'600px', width:'100vw' }} ref={this.mapContainer}></div>
           </Row>
            </>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    setBreadcrumbPaths: PropTypes.func,
};
const mapStateToProps = (state) => ({});

export default connect(null, { setBreadcrumbPaths })(Main);;

