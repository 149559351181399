/**
 *  面包屑组件
 * */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

class Breadcrumb extends React.Component {

    _renderItem = (item, index, list) => {
        const { history } = this.props;
        if (index == 0) {
            return <span key={index}><span>{item.title}</span></span>;
        } else if (index == list.length - 1) {
            return <span key={index}><span className={styles.mark}>{'>'}</span><span>{item.title}</span></span>;
        } else {
            return <span key={index}><span className={styles.mark}>{'>'}</span><span className={styles.title} onClick={() => {
                let link = item.path;
                if (typeof (link) == 'string') {
                    history.push({
                        pathname: link,
                    });
                } else {
                    history.push(link);
                }
            }}>{item.title}</span></span>;
        }
    }

    render() {
        const { renderData } = this.props;
   
        return (
            <div className={styles.container}>
                {renderData.map((item, index, list) => {
                    return this._renderItem(item, index, list);
                })}
            </div>
        );
    }
}

Breadcrumb.propTypes = {
    renderData: PropTypes.array, // 渲染数据源，参数格式[{title:'', path:''}, ...]
    history: PropTypes.object,
};

export default Breadcrumb;