/** 
 * 异步请求总控中心
 * put相当于redux的dispatch的作用
 * */
import { fork, all } from 'redux-saga/effects';
import { getMenuList } from '@/reducers/home/saga';
import cmangeSaga from '@/reducers/cmange/combineSaga';
import cintroductionSaga from '@/reducers/cintroduction/combineSaga';
import enumSaga from '@/reducers/enum/combineSaga';
import systemSaga from '@/reducers/csystem/combineSaga'

const combineSaga = (sagas, modules) => {
    let result = [...sagas];
    for (let i = 0; i < modules.length; i++) {
        const module = modules[i];
        result = result.concat(module.map((item) => {
            return fork(item);
        }));
    }
    return result;
};

const modules = [
    cmangeSaga,
    cintroductionSaga,
    enumSaga,
    systemSaga,
];
let sagas = [].concat(fork(getMenuList));
sagas = combineSaga(sagas, modules);
export default function* rootSaga() {
    yield all(sagas);
}