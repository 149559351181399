/**
 * 带标题的日期+时间选择框
 <XDatePicker
    label='签署日期'
    placeholder='请选择开始日期'
    value={this.state.time}
    bindThis={this}
    bindName='time'
    isFormat={true}
    showTime={true}
 />
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { DatePicker } from 'antd';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');

class DatePickerModule extends React.Component {

    _onChange = (value) => {
        let time = value;
        if (this.props.isFormat) {
            time = moment(value).format('YYYY-MM-DD HH:mm:ss');
        }

        if (!this.props.showTime && this.props.defaultTime != '') {
            time = moment(time).format('YYYY-MM-DD') + ' ' + this.props.defaultTime;
        }

        const { bindThis, bindName, onChange } = this.props;
        if (onChange) {
            onChange(time);
        }
        if (value == null) {
            if (typeof bindThis === 'function') {
                bindThis(undefined);
            } else {
                bindThis && bindThis.setState({ [bindName]: undefined });
            }
        } else if (bindThis && bindName) {
            if (typeof bindThis === 'function') {
                bindThis(time);
            } else {
                bindThis.setState({ [bindName]: time });
            }
        }
    }


    render() {
        const { style, datePickerStyle, label, value, isRequired, showTime, bindThis, bindName, isFormat, ...otherProps } = this.props;
        return (
            <div className={styles.container} style={style}>
                <div className={styles.label}>{label}{ }</div><span className={styles.star} style={isRequired ? { display: 'block' } : { display: 'none' }}>*</span>
                <div className={styles.picker}>
                    <DatePicker
                        {...otherProps}
                        style={datePickerStyle}
                        value={value ? moment(value) : null}
                        locale={locale}
                        showTime={showTime}
                        onChange={this._onChange}
                        onOk={this._onChange}
                    />
                </div>
            </div>
        );
    }
}

DatePickerModule.defaultProps = {
    isRequired: false,
    isFormat: false,
    showTime: true,
    placeholder: '请选择时间',
    defaultTime: '',
    datePickerStyle: { width: '150px' }
};

DatePickerModule.propTypes = {
    key: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    bindThis: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    style: PropTypes.object, // 总样式
    datePickerStyle: PropTypes.object, // 输入框样式
    label: PropTypes.string, // 标题名称
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]), // 显示的时间值，moment格式对象（双向绑定）, // 显示的时间值，moment格式对象（双向绑定）
    onChange: PropTypes.func, // 日期变化时的回调函数
    isRequired: PropTypes.bool, // 是否为必须 
    isFormat: PropTypes.bool, // 双向绑定的返回值是否需要格式化为YYYY-MM-DD HH:mm:ss
    showTime: PropTypes.bool, // 是否显示时间
    defaultTime: PropTypes.string // 默认的时间值（用于只选择日期时，设置当前日期的时间默认值）
};

export default DatePickerModule;