export const SET_BREADCRUMB_PATHS = 'HOME/SET_BREADCRUMB_PATHS';
export const CLEAN_BREADCRUMB_PATHS = 'HOME/CLEAN_BREADCRUMB_PATHS';
export const ADD_ONE_BREADCRUMB_PATH = 'HOME/ADD_ONE_BREADCRUMB_PATH';
export const KEEP_SECOND_NAV_BREADCRUMB = 'HOME/KEEP_SECOND_NAV_BREADCRUMB';
export const KEEP_THIRD_NAV_BREADCRUMB = 'HOME/KEEP_THIRD_NAV_BREADCRUMB';
export const SET_FIRST_CARD_PATH = 'HOME/SET_FIRST_CARD_PATH';
export const SET_SECOND_CARD_PATH = 'HOME/SET_SECOND_CARD_PATH';
export const SET_THIRD_CARD_PATH = 'HOME/SET_THIRD_CARD_PATH';
export const SET_STRETCHOPEN = 'HOME/SET_STRETCHOPEN';
export const GET_MENUS_LIST = 'HOME/GET_MENUS_LIST';
export const SET_MENUS_LIST = 'HOME/SET_MENUS_LIST';