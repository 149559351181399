/**
 * 一个全选复选 + 多个独立复选框组合的组件
 * □ 全选
 * □ 复选1 □ 复选2 □ 复选3
 <XCheckAll
    options={['Apple', 'Pear', 'Orange']}
    defaultCheckedList={['Apple', 'Pear', 'Orange']}
    onCheckAllChange={()=>{}}
    onChange={()=>{}}>
 </XCheckAll> 
*/
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

const CheckboxGroup = Checkbox.Group;
class CheckAll extends React.Component {

    state = {
        checkedList: [], // 选中的数组
        indeterminate: false, // 设置 indeterminate 状态，只负责样式控制
        checkAll: false, // 全选当前是否选中
    };

    componentDidMount() {
        const { defaultCheckedList, options } = this.props;
        this.setState({ checkedList: defaultCheckedList, checkAll: defaultCheckedList && options && defaultCheckedList.length == options.length });
    }

    // 子级复选框变化时触发
    onChange = (checkedList) => {
        const { options } = this.props;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < options.length,
            checkAll: checkedList.length === options.length,
        });
        if (this.props.onChange) this.props.onChange(checkedList);
    };

    // 全选复选框变化时触发
    onCheckAllChange = e => {
        const { options } = this.props;
        this.setState({
            checkedList: e.target.checked ? options : [],
            indeterminate: false,
            checkAll: e.target.checked,
        }, () => {
            if (this.props.onCheckAllChange) this.props.onCheckAllChange(this.state.checkedList);
        });
    };

    render() {
        const { checkAllLabel, disabled, checkAllStyle, checkGroupStyle, options } = this.props;
        const { checkedList, indeterminate, checkAll } = this.state;

        return (
            <>
                <Checkbox
                    style={checkAllStyle}
                    disabled={disabled}
                    indeterminate={indeterminate}
                    onChange={this.onCheckAllChange}
                    checked={checkAll}
                >
                    {checkAllLabel}
                </Checkbox>
                <CheckboxGroup
                    style={checkGroupStyle}
                    disabled={disabled}
                    options={options}
                    value={checkedList}
                    onChange={this.onChange}
                />
            </>
        );
    }
}

CheckAll.defaultProps = {
    checkAllStyle: { display: 'block' },
    checkAllLabel: '全选',
    disabled: false,
};

CheckAll.propTypes = {
    disabled: PropTypes.bool, //  是否为禁用状态
    checkAllLabel: PropTypes.string, // 全选复选框文案
    checkAllStyle: PropTypes.object, // 全选复选框样式
    onCheckAllChange: PropTypes.func, // 全选时变化时回调函数
    checkGroupStyle: PropTypes.object, // 子级复选框样式
    onChange: PropTypes.func, // 子级复选框变化时回调函数
    defaultCheckedList: PropTypes.array, // 子级复选框指定选中的选项，配合initialValue使用
    options: PropTypes.array,// 子级复选框选项，格式：['Apple', 'Pear', 'Orange']
};

export default CheckAll;