/**
 * 带标题的下拉列选择器 
 <XSelect
    label='状态'
    placeholder='请选择'
    renderData={[{ key: '1', value: '1' }, { key: '2', value: '2' }]}
    onChange={(data) => this.setState({ data })}
    dataIndex='value'
    keyIndex='key'
    value={this.state.data.key}
 />
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const Option = Select.Option;

class SelectModule extends React.Component {

    _onSelectChange = (value, item = {}) => {
        const { bindThis, bindName, onChange, renderData, keyIndex } = this.props;
        const itemKey = item.key;
        let selectItem = {};
        for (let i = 0; i < renderData.length; i++) {
            if (renderData[i][keyIndex] == itemKey) {
                selectItem = renderData[i];
                break;
            }
        }
        if (onChange) {
            onChange(selectItem);
        }
        if (bindThis && bindName) {
            if (typeof bindThis === 'function') {
                bindThis(selectItem);
            } else {
                bindThis.setState({ [bindName]: selectItem });
            }
        }
    }

    _renderSelectItem = (dataSource, keyIndex, dataIndex) => {
        let ovbind = dataIndex;
        if (this.props.isOptionBindID) {
            ovbind = keyIndex;
        }
        return dataSource.map((item) => {
            return (
                <Option key={item[keyIndex]} value={item[ovbind]}>{item[dataIndex]}</Option>
            );
        });
    }

    render() {
        const { label, renderData, keyIndex, dataIndex, style, selectStyle, isRequired, bindThis, bindName, isOptionBindID, ...otherProps } = this.props;

        return (
            <div style={style} className={styles.container}>
                <span className={styles.star} style={isRequired ? { display: 'block' } : { display: 'none' }}>*</span>
                <div className={styles.label} style={label && label.length > 4 ? { minWidth: '40px' } : null}>{label}</div>
                <div className={styles.select}>
                    <Select
                        {...otherProps}
                        style={selectStyle}
                        onChange={this._onSelectChange}
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {this._renderSelectItem(renderData, keyIndex, dataIndex)}
                    </Select>
                </div>
            </div>
        );
    }
}

SelectModule.defaultProps = {
    isRequired: false,
    isOptionBindID: false,
    style: { marginRight: '20px' },
    selectStyle: { width: '150px' },
};

SelectModule.propTypes = {
    label: PropTypes.string, // 标签名称
    renderData: PropTypes.array.isRequired, // 渲染下拉列数据源
    keyIndex: PropTypes.string.isRequired, // 数据源在下拉列中绑定的ID属性
    dataIndex: PropTypes.string.isRequired, // 数据源在下拉列中显示的文本属性
    style: PropTypes.object, // 组件样式
    selectStyle: PropTypes.object, // 选择器样式
    bindThis: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    onChange: PropTypes.func, // 下拉列变化时的回调函数
    isRequired: PropTypes.bool, // 是否显示红星
    isOptionBindID: PropTypes.bool, // 是否Option的value使用ID为绑定
};

export default SelectModule;