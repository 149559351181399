/**
 * 搜索条件打开、隐藏切换图标组件
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

class ExpandOrContract extends React.Component {

    state = {
        expandBoolean: this.props.isExpandDefault
    }

    clickHandle = () => {
        this.setState({ expandBoolean: !this.state.expandBoolean });
        if (this.props.onChange) this.props.onChange(!this.state.expandBoolean);
    }

    renderIcon = () => {
        if (this.state.expandBoolean) {
            return <img className={styles.icon} src={require('@/assets/image/contract.png').default} />;
        } else {
            return <img className={styles.icon} src={require('@/assets/image/expand.png').default} />;
        }
    }

    renderLabel = () => {
        if (this.state.expandBoolean) {
            return <div className={styles.label}>隐藏</div>;
        } else {
            return <div className={styles.label}>更多</div>;
        }
    }

    render() {
        return (
            <div style={this.props.style} className={styles.container} onClick={this.clickHandle}>
                {this.renderIcon()}
                {this.renderLabel()}
            </div>
        );
    }
}

ExpandOrContract.defaultProps = {
    isExpandDefault: false,
};

ExpandOrContract.propTypes = {
    style: PropTypes.object, // 总样式
    onChange: PropTypes.func, // 展开与收缩触发的回调函数（回调参数：true展开；false收缩）
    isExpandDefault: PropTypes.bool,  // 是否展开的默认值设置（默认：false）
};

export default ExpandOrContract;