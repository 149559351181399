/**
 * 带标题的输入框组件
 <XInput
    label='手机号码'
    placeholder='请输入手机号码'
    bindThis={this}
    bindName='tel'
    value={this.state.tel}
    maxLength={11}
    mode={'number'}
 />
 */
import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';
import { Input } from 'antd';

class InputModule extends React.Component {

    state = {
        textNum: 0,
    };

    _onChange = (e) => {
        const { bindThis, bindName, onChange, maxLength, mode } = this.props;

        if (mode) {
            let reg;
            mode === 'order' && (reg = /^[A-Z0-9]+$/);
            mode === 'number' && (reg = /^-?[0-9]*([0-9]*)?$/);
            mode === 'digit' && (reg = /^-?[0-9]*(\.[0-9]*)?$/);
            mode === 'chiLetNum' && (reg = /^[\u4e00-\u9fa5a-zA-Z0-9·]+$/);
            let value = e.target.value;
            if (!((reg && reg.test(value)) || value === '' || value === '-')) {
                return;
            }
        }

        this.setState({ textNum: e.target.value.length });
        if (onChange) {
            onChange(e.target.value);
        }
        if (bindThis && bindName) {
            if (typeof bindThis === 'function') {
                if (e.target.value.length > maxLength) {
                    bindThis(e.target.defaultValue);
                    this.setState({ textNum: e.target.defaultValue.length });
                } else {
                    bindThis(e.target.value);
                }
            } else {
                if (e.target.value.length > maxLength) {
                    bindThis.setState({ [bindName]: e.target.defaultValue });
                    this.setState({ textNum: e.target.defaultValue.length });
                } else {
                    bindThis.setState({ [bindName]: e.target.value });
                }
            }
        }
    }

    render() {
        const { label, style, inputStyle, isRequired, bindThis, bindName, showLength, ...otherProps } = this.props;
        const { textNum } = this.state;
        return (
            <div style={style} className={styles.container}>
                <span className={styles.star} style={isRequired ? { display: 'block' } : { display: 'none' }}>*</span>
                <div className={styles.label}>{label}</div>
                <div className={styles.inputbox}>
                    <Input
                        {...otherProps}
                        style={inputStyle}
                        className={styles.input}
                        onChange={this._onChange}
                    />
                </div>
                {showLength && this.props.maxLength ? <span style={{ marginLeft: 2 }}>（{textNum}/{this.props.maxLength}）</span> : null}
            </div>
        );
    }
}

InputModule.defaultProps = {
    isRequired: false,
    showLength: false,
    style: { marginRight: '20px' },
    inputStyle: { width: '150px' },
};

InputModule.propTypes = {
    label: PropTypes.string, // 标题名称
    style: PropTypes.object, // 组件样式
    inputStyle: PropTypes.object, // 输入框样式
    isRequired: PropTypes.bool, // 当为true时样式添加红星
    bindThis: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), // 父级this
    bindName: PropTypes.string, // 双向绑定的属性名称
    onChange: PropTypes.func, // 输入框内容变化时的回调函数
    maxLength: PropTypes.number, // 输入的最大长度
    showLength: PropTypes.bool, // 是否在输入框后面显示已输入的长度
    mode: PropTypes.string, // 默认都可以输入，当为number，只允许输入数字；当为digit允许输入带小数点的数字,当为chiLetNum允许输入汉字、字母、汉字、_
};

export default InputModule;