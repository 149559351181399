import React from 'react';
import PropTypes from 'prop-types';
import { getLocalStorage } from '@/assets/js/storage';
import http from '@/assets/api/http';
import { message } from 'antd';

class ButtonPerssion extends React.Component {

    state = {
        hasPerssion: false, // 是否显示
        disabled: false, // 是否只读
        tabList: [],
        C: null
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.render) {
            const { code } = this.props;
            if (Array.isArray(code)) {
                this.setState({ C: props.render(this.state.tabList) });
            } else {
                this.setState({ C: props.render(this.state.hasPerssion, this.state.disabled) });
            }
        }
    }

    componentDidMount() {
        let btnPremission = JSON.parse(getLocalStorage('btnPremission')) || [];
        if (btnPremission.length == 0) {
            http('/?cmd=com.xqxc.api.pig.mis.biz.account.MenuMisService.listMenus', {}, 'POST').then((response) => {
                btnPremission = this._saveButtonPermission(response.result);
                this._check(btnPremission);
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            this._check(btnPremission);
        }
    }

    _check = (btnPremission) => {
        const { code } = this.props;
        let hasPerssion = false;
        let disabled = '';
        let tabList = [];
        for (let i = 0; i < btnPremission.length; i++) {
            if (Array.isArray(code)) {
                // tab权限
                for (let m = 0; m < code.length; m++) {
                    if (btnPremission[i].url == code[m]) {
                        tabList.push({ code: code[m], hasPerssion: true, disabled: btnPremission[i].type == 'READ_ONLY' ? true : false });
                        break;
                    }
                }
            } else {
                // 按钮权限
                if (btnPremission[i].url == code) {
                    hasPerssion = true;
                    disabled = btnPremission[i].type == 'READ_ONLY' ? true : false;
                    break;
                }
            }
        }

        if (Array.isArray(code) && tabList.length > 0) {
            this.setState({ tabList });
            if (this.props.render) {
                this.setState({ C: this.props.render(tabList) });
            }
        } else if (hasPerssion) {
            this.setState({ hasPerssion, disabled });
            if (this.props.render) {
                this.setState({ C: this.props.render(hasPerssion, disabled) });
            }
        }
    }

    _saveButtonPermission = (menuList) => {
        const premission = this._getButtonUrlForChildren(menuList);
        return premission;
    }

    _getButtonUrlForChildren = (treeData) => {
        let childrenEventKeys = [];
        for (let i = 0; i < treeData.length; i++) {
            if (treeData[i].menuType == '2' || treeData[i].menuType == '3') {
                childrenEventKeys.push(treeData[i].menuUrl);
            }
            if (treeData[i].childrens && treeData[i].childrens.length > 0) {
                const array = this._getButtonUrlForChildren(treeData[i].childrens);
                childrenEventKeys = Array.from(new Set([...childrenEventKeys, ...array]));
            }
        }
        return childrenEventKeys;
    }

    render() {
        const { C } = this.state;
        return (
            <>
                {
                    C ? C :
                        <span>
                            {this.props.children}
                        </span>
                }
            </>
        );
    }
}

ButtonPerssion.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    render: PropTypes.func,
};

export default ButtonPerssion;