import React from 'react';
import PropTypes from 'prop-types';
import styles from './SafeItem.module.scss';
import { Avatar } from 'antd';
import { XOKButton } from '@/components/xqxc_ui';

class SafeItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.data.id,
            icon: props.data.icon,
            title: props.data.title,
            subTitle: props.data.subTitle,
            content: props.data.content,
            btnLabel: props.data.btnLabel
        };
    }

    render() {
        const { id, icon, title, subTitle, content, btnLabel } = this.state;

        return (
            <div className={styles.container}>
                <div className={styles.avatar}>
                    <Avatar style={{ minWidth: '40px', minHeight: '40px', cursor: 'pointer' }} size={40} icon="U" src={icon} />
                    <div className={styles.tbox}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.subTitle}>{subTitle}</div>
                    </div>
                </div>
                <div className={styles.content}>
                    {content}
                </div>
                <XOKButton style={{ width: '100px', marginRight: '50px' }} label={btnLabel} onClick={() => { this.props.onClick(id); }} />
            </div>
        );
    }
}

SafeItem.propTypes = {
    data: PropTypes.object,
    onClick: PropTypes.func,
};

export default SafeItem;