/**
 * 账号管理
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { message } from 'antd';
import { XInput, XSelect, XOKButton, XCancelButton, showConfirm } from '@/components/xqxc_ui';
import { SearchBox, SearchItem } from '@/components/Layout';
import KeepAlive from '@/routes/KeepAlive';
import TableContent from './components/TableContent';
import { autoPageNum, autopageSize } from '@/assets/config';
import { setBreadcrumbPaths } from '@/reducers/home/actions';
import { connect } from 'react-redux';
import model from '@/reducers/csystem/version/model';
import enummodel from '@/reducers/enum/model';
import http from '@/assets/api/http';

class Main extends React.Component {

    state = {
        newVersion: '',
        appType: {},
        status: {},
    };

    componentDidMount() {
        this.props.setBreadcrumbPaths(this.props.match); // 自动识别面包屑
        this.props.getAppTypeEnum();
        this.props.getStateEnum();
    }

    // 新建
    _newVersionHandle = () => {
        this.props.history.push({ pathname: `/home/csystem/version/add` });
    }

    // 查询
    _searchHandle = (useCache) => {
        const { newVersion, appType, status, pageNum, pageSize } = this.state;
        const query = {
            newVersion,
            appType: appType.code,
            state: status.code,
            pageNum: useCache == 'useCache' ? pageNum : autoPageNum,
            pageSize: useCache == 'useCache' ? pageSize : autopageSize,
        };
        this.props.getVersionList(query);
    }

    // 重置
    _resetHandle = () => {
        this.setState({
            newVersion: '',
            appType: {},
            status: {},
            pageNum: autoPageNum,
            pageSize: autopageSize,
        });
    }

    // 分页
    _paginationChange = (pageSize, pageNum) => {
        this.setState({ pageNum, pageSize }, () => { this._searchHandle('useCache'); });
    }

    // 表格操作
    _tableAction = (type, record) => {
        console.log(type, record);
        const { history } = this.props;
        const { id } = record;
        switch (type) {
            case 'edit':
                history.push({ pathname: `/home/csystem/version/edit/${id}` });
                break;
            case 'on':
                http('/?cmd=com.xqxc.api.pf.PfAppVersionManageService.updateAppVersionState', { id, state: 1 }, 'POST').then((response) => {
                    message.success('启用版本成功');
                    this._searchHandle('useCache');
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'off':
                http('/?cmd=com.xqxc.api.pf.PfAppVersionManageService.updateAppVersionState', { id, state: 0 }, 'POST').then((response) => {
                    message.success('禁用版本成功');
                    this._searchHandle('useCache');
                }).catch((e) => {
                    message.error(e.message);
                });
                break;
            case 'delete':
                showConfirm('删除确认', '是否要删除该APP版本？', () => {
                    http('/?cmd=com.xqxc.api.pf.PfAppVersionManageService.delAppVersion', { id }, 'POST').then((response) => {
                        message.success('删除成功');
                        this._searchHandle('useCache');
                    }).catch((e) => {
                        message.error(e.message);
                    });
                });
                break;
        }
    }

    render() {
        const { newVersion, appType, status } = this.state;
        const { appTypeEnum, stateEnum, versionList } = this.props;

        return (
            <KeepAlive render={(state) => {
                if (!this.state.__aliveInit) {
                    this.setState({ ...state, __aliveInit: true }, () => {
                        this._searchHandle('useCache');
                    });
                }
            }}>
                <div className={styles.flexBoxContainer}>
                    <SearchBox>
                        <SearchItem>
                            <XOKButton style={{ width: 100, marginRight: 20 }} label='新建版本' onClick={this._newVersionHandle} />
                        </SearchItem>
                        <SearchItem>
                            <XInput
                                label='版本号'
                                placeholder='请输入版本号'
                                value={newVersion}
                                bindThis={this}
                                bindName='newVersion'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='所属终端'
                                placeholder='请选择所属终端'
                                renderData={appTypeEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={appType.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='appType'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XSelect
                                label='状态'
                                placeholder='请选择状态'
                                renderData={stateEnum}
                                dataIndex='value'
                                keyIndex='code'
                                value={status.value}
                                showSearch={true}
                                bindThis={this}
                                bindName='status'
                            />
                        </SearchItem>
                        <SearchItem>
                            <XOKButton label='查询' onClick={this._searchHandle} />
                            <XCancelButton label='重置' onClick={this._resetHandle} />
                        </SearchItem>
                    </SearchBox>
                    <TableContent
                        renderData={versionList}
                        tableAction={this._tableAction}
                        paginationChange={this._paginationChange}
                    />
                </div>
            </KeepAlive>
        );
    }
}

Main.propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    setBreadcrumbPaths: PropTypes.func,
    getAppTypeEnum: PropTypes.func,
    appTypeEnum: PropTypes.array,
    getStateEnum: PropTypes.func,
    stateEnum: PropTypes.array,
    getVersionList: PropTypes.func,
    versionList: PropTypes.object,
};

const mapStateToProps = (state) => ({
    appTypeEnum: state.enumGlobal.getAppTypeEnum,
    stateEnum: state.enumGlobal.getStateEnum,
    versionList: state.version.getVersionList,
});

export default connect(mapStateToProps, { setBreadcrumbPaths, ...model.actions, ...enummodel.actions })(Main);