/**
 * 路由配置
 */
import Login from '../views/login';
import Home from '../views/home';
import Welcome from '../views/home/welcome';
import Setting from '../views/setting';
import AsyncComponent from './asyncComponent.js';
import { Account } from '../views/cmanage';
import { InitList } from '../views/cintroduction';
import { Overview, Workload, Workquality } from '../views/cdata';
import { Version } from '../views/csystem';

// 客服管理
const cmanageRoutes = [
    { path: '/account', title: '商家账号', component: Account },
    { path: '/account/create', title: '新建账号', component: AsyncComponent(() => import('@/views/cmanage/account/create')), show: 'none' },
    { path: '/account/edit/:id', title: '编辑账号', component: AsyncComponent(() => import('@/views/cmanage/account/edit')), show: 'none' },
];

// 攻略管理
const cintroductionRoutes = [
    { path: '/initlist', title: '初始说明列表', component: InitList },
    { path: '/initlist/edit/:id', title: '编辑', component: AsyncComponent(() => import('@/views/cintroduction/initlist/edit')), show: 'none' },
];

// 系统管理
const cversion = [
    { path: '/version', title: '版本管理', component: Version },
    { path: '/version/add', title: '新增', component: AsyncComponent(() => import('@/views/csystem/version/addedit')), show: 'none' },
    { path: '/version/edit/:id', title: '编辑', component: AsyncComponent(() => import('@/views/csystem/version/addedit')), show: 'none' },
];

// 客服数据
// const cdataRoutes = [
//     { path: '/overview', title: '数据总览', component: Overview },
//     { path: '/workload', title: '工作量统计', component: Workload },
//     { path: '/workquality', title: '工作质量统计', component: Workquality },
// ];

// 路由配置
const routes = [
    { path: '/', component: Login, exact: true },
    { path: '/login', component: Login },
    {
        path: '/home', component: Home, routes: [
            {
                path: '/cglobal',
                icon: require('@/assets/image/cdata.png').default,
                title: '概况',
                component: AsyncComponent(() => import('@/views/cglobal')),
                childrenNode: []
            },
            {
                path: '/cmanage',
                icon: require('@/assets/image/cmanage.png').default,
                title: '商家管理',
                childrenNode: cmanageRoutes
            },
            {
                path: '/cintroduction',
                icon: require('@/assets/image/competition.png').default,
                title: '攻略管理',
                childrenNode: cintroductionRoutes
            },
            {
                path: '/csystem',
                icon: require('@/assets/image/system.png').default,
                title: '系统管理',
                childrenNode: cversion
            },
            // { path: '/cdata', icon: (require('@/assets/image/cdata.png').default), title: '客服数据', childrenNode: cdataRoutes },
        ]
    },
];

export default routes;