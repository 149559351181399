/**
 * 用于实现antd表格高度自适应
 */
import React from 'react';
import PropTypes from 'prop-types';

class TableAdapter extends React.Component {

    constructor() {
        super();
        this.state = {
            clientTop: 0,
        };
        window.addEventListener('resize', this._forceUpdateEvent);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._forceUpdateEvent);
    }

    _forceUpdateEvent = () => {
        this.forceUpdate();
    }

    render() {
        return (
            <div
                ref={(ele) => {
                    const { offsetY } = this.props;
                    const clientRect = ele && ele.getBoundingClientRect();
                    if (clientRect && this.state.clientTop != clientRect.top + 116 + offsetY) {
                        this.setState({ clientTop: clientRect.top + 116 + offsetY }, () => {
                            const scrollY = 'calc(100vh - ' + this.state.clientTop + 'px)';
                            this.props.render(scrollY);
                        });
                    }
                }}>
                {this.props.children}
            </div>
        );
    }
}

TableAdapter.defaultProps = {
    offsetY: 0
};

TableAdapter.propTypes = {
    children: PropTypes.element,
    render: PropTypes.func,
    offsetY: PropTypes.number,
};

export default TableAdapter;