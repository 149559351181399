import React from 'react';
import styles from './NavCard.module.scss';
import PropTypes from 'prop-types';
import { setFirstCardPath } from '@/reducers/home/actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { createHashHistory } from 'history';
const history = createHashHistory();

class NavCard extends React.Component {

    state = {
        carditemActived: false // 当前导航卡是否在活动中
    }

    componentDidMount() {
        const { match, rItem, firstCardPath } = this.props;
        const compCardPath = match.path + rItem.path;
        if (firstCardPath === compCardPath) {
            this.setState({ carditemActived: true });
        }
        this.unListenHistory = history.listen((history) => {
            this.props.setFirstCardPath(history.location.pathname);
        });
    }

    // componentWillUnmount() {
    //     this.unListenHistory();
    // }


    UNSAFE_componentWillReceiveProps(newProps) {
        const { match, rItem, firstCardPath, isToggleCard } = newProps;
        const compCardPath = match.path + rItem.path;
        console.log(newProps);
        // 点击Card分类时，若隐藏则打开；若已打开则隐藏
        //

        if (firstCardPath === compCardPath) {
            if (this.state.carditemActived && isToggleCard) {
                this.setState({ carditemActived: false });
            } else {
                this.setState({ carditemActived: true });
            }
        } else {
            this.setState({ carditemActived: false });
        }
    }

    render() {
        const { match, rItem, toggleCardHandle, putRouteHandle, secondCardPath } = this.props;
        const cardPath = match.path + rItem.path;
        const cardTitle = rItem.title;
        const cnodes = rItem.childrenNode.filter((cNode) => {
            if (cNode.show != 'none') {
                return true;
            }
        });
        const mt = -(cnodes.length * 40) + 'px';
        return (
            <div className={`${styles.navcard} ${this.state.carditemActived ? styles.navcard_actived : ''}`}>
                <div className={`${styles.cardhome} ${this.state.carditemActived ? styles.cardhome_actived : ''}`} onClick={() => toggleCardHandle(cardPath)}>
                    <img className={styles.icon} src={rItem.icon} />
                    <div className={`${styles.title} ${this.state.carditemActived ? styles.title_actived : ''}`}>{rItem.title}</div>
                    <img className={styles.expand} src={this.state.carditemActived ? require('@/assets/image/shangla.png').default : require('@/assets/image/xiala.png').default } />
                </div>

                <div
                    className={styles.carditem_animate}
                    style={this.state.carditemActived ? { marginTop: '0px' } : { marginTop: mt }}
                >
                    {
                        rItem.childrenNode.map((cNode) => {
                            const cardItemPath = match.path + rItem.path + cNode.path;
                            const cardItemTitle = cNode.title;
                            const breadcrumbPaths = [].concat({ title: cardTitle, path: cardPath }, { title: cardItemTitle, path: cardItemPath }); // 面包屑导航数据
                            let ele = null;
                            if (cNode.show != 'none') {
                                // 若当前的路由路径和cardItem自身路由路径相同时，样式触发为actived
                                ele = (
                                    <div className={`${cardItemPath === secondCardPath ? styles.active_carditem : ''} ${styles.carditem}`} key={cardItemPath} onClick={() => putRouteHandle(cardItemPath, breadcrumbPaths)}>
                                        <div className={styles.itemtitle}>{cNode.title}</div>
                                    </div>
                                );
                            }
                            return ele;
                        })
                    }
                </div>
            </div>
        );
    }
}

NavCard.propTypes = {
    match: PropTypes.object,
    rItem: PropTypes.object,
    location: PropTypes.object,
    setFirstCardPath: PropTypes.func,
    isToggleCard: PropTypes.bool,
    toggleCardHandle: PropTypes.func,
    putRouteHandle: PropTypes.func,
    firstCardPath: PropTypes.string,
    secondCardPath: PropTypes.string,
    stretchOpen: PropTypes.bool
};

export default connect(null, { setFirstCardPath})(withRouter(NavCard));
