/**
 * 底部横线+确认按钮+返回按钮组合组件
 * <XLineConfirmBtn okHandle={() => { }} cancelHandle={() => { }} />
 */
import React from 'react';
import styles from './index.module.scss';
import OKButton from '../OKButton';
import CancelButton from '../CancelButton';
import PropTypes from 'prop-types';

class LineConfirmBtn extends React.Component {

    render() {
        const { style, okHandle, cancelHandle, okTitle, cancelTitle, loading } = this.props;
        return (
            <div className={styles.container} style={style}>
                <div className={styles.line}></div>
                <div className={styles.button}>
                    <OKButton style={{ width: '90px' }} label={okTitle} onClick={okHandle} loading={loading} />
                    <CancelButton style={{ width: '90px', marginLeft: '10px' }} label={cancelTitle} onClick={cancelHandle} />
                </div>
            </div>
        );
    }
}

LineConfirmBtn.defaultProps = {
    okTitle: '确认',
    cancelTitle: '返回'
};

LineConfirmBtn.propTypes = {
    style: PropTypes.object, // 总样式
    okHandle: PropTypes.func, // 点击确认按钮时的回调函数
    cancelHandle: PropTypes.func, // 点击取消按钮时的回调函数
    okTitle: PropTypes.string, // 确认标题
    cancelTitle: PropTypes.string, // 返回标题
    loading: PropTypes.bool
};

export default LineConfirmBtn;