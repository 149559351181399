import React from 'react';
import styles from './BackGround.module.scss';

const BackGround = () => {
    return (
        <div className={styles.bg}>
            <div className={styles.layout}></div>
            <div className={styles.companyname}>秀商时代（重庆）科技有限公司 <a href='https://xyt.xcc.cn/getpcInfo?sn=1561907592370782208&language=CN&certType=8&url=*.daily-talk.com' target='_blank' style={{position: 'relative', marginLeft:'10px'}}>
                <div style={{width:'100%', height:'100%', position: 'absolute', top: 0, left: 0}}></div>
                <embed src='https://program.xinchacha.com/web/1561907592370782208=*.daily-talk.com.svg'width='103' height='38' type='image/svg+xml' pluginspage='//www.adobe.com/svg/viewer/install/'/>
            </a></div>
        </div>
    );
};

export default BackGround;