import React from 'react';
import styles from './LoginBox.module.scss';
import PropTypes from 'prop-types';
import http from '@/assets/api/http';
import { message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Icon, Checkbox, Button } from 'antd';
import { XInput, XInputPassword, XOKButton } from '@/components/xqxc_ui';
import { setFirstCardPath, setSecondCardPath, setStretchOpen, cleanBreadcrumbPaths } from '@/reducers/home/actions';
import { setAuth } from '@/reducers/auth/actions';
import { connect } from 'react-redux';
import { getLocalStorage } from '@/assets/js/storage';
import md5 from 'js-md5';

class LoginBox extends React.Component {

    timeID = '';

    state = {
        account: '', // 登录账号
        pwd: '', // 登录密码
        keepPwd: false, // 记住密码
        error: false, // 验证错误
        status: 1, // 1：登录状态  2：忘记密码  3：设置新密码
        telCaptcha: '', // 验证码
        tel: '', // 手机号
        newpwd: '', // 新密码
        newpwdSure: '', // 新密码确认
        alreadySetCaptcha: false, // 是否已经发送验证码，是为true
        time: 60, // 发送验证码倒计时
    }

    componentDidMount() {
        if (getLocalStorage('auth')) {
            const auth = JSON.parse(getLocalStorage('auth'));
            const { account, pwd, keepPwd } = auth;
            if (auth.keepPwd) {
                this.setState({ account, pwd, keepPwd });
            } else {
                this.setState({ account });
            }
        }
        document.addEventListener('keypress', this.handleEnterKey);
    }

    componentWillUnmount() {
        document.removeEventListener('keypress', this.handleEnterKey);
    }

    handleEnterKey = (e) => {
        if (e.keyCode === 13) {
            this._loginHandle();
        }
    }

    _keepPwdChange = (e) => {
        this.setState({ keepPwd: e.target.checked });
    }

    // 登录操作
    _loginHandle = () => {
        const { setFirstCardPath, setSecondCardPath, setStretchOpen, cleanBreadcrumbPaths, history, setAuth } = this.props;
        const { account, pwd } = this.state;
        const data = {
            account,
            pwd: md5(pwd).toLowerCase()
        };
        http('/?cmd=com.xqxc.api.sys.SysAdminService.sysAdminLogin', data, 'POST').then((response) => {
            let auth = { ...response.result, account: this.state.account, pwd: this.state.pwd, keepPwd: this.state.keepPwd };
            setAuth(auth);
            // 重置相关导航记录
            setFirstCardPath('/home/cglobal');
            setSecondCardPath('');
            cleanBreadcrumbPaths();
            setStretchOpen(true);
            // 跳转至home
            history.push({ pathname: '/home/cglobal' });
            message.success('登录成功');
        }).catch(() => {
            this.setState({ error: true });
        });
    };

    // 获取验证码
    _getCaptcha = () => {
        const { account, tel } = this.state;
        const _this = this;
        const data = {
            account,
            tel,
            captchaType: 'BACKPASSWORD'
        };

        http('/admin/empl/getTelCaptcha', data, 'POST').then((response) => {
            this.setState({ alreadySetCaptcha: true });
            this.timeID = setInterval(function () {
                const { time } = _this.state;
                if (time > 0) {
                    _this.setState({ time: time - 1 });
                } else {
                    clearInterval(_this.timeID);
                    _this.setState({ alreadySetCaptcha: false, time: 60 });
                }
            }, 1000);
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 忘记密码，点击下一步操作
    _next = () => {
        const { account, tel, telCaptcha } = this.state;
        const data = {
            account,
            tel,
            telCaptcha
        };
        http('/admin/empl/verifyTelCaptcha', data, 'POST').then((response) => {
            clearInterval(this.timeID);
            this.setState({ status: 3, alreadySetCaptcha: false, time: 60 });
        }).catch((e) => {
            message.error(e.message);
        });
    }

    // 保存新密码
    _saveNewPw = () => {
        const { newpwd, newpwdSure, account, tel, telCaptcha } = this.state;
        if (newpwd != newpwdSure) {
            message.error('两次输入的新密码不一致！');
            return;
        }
        if (newpwd.length < 6) {
            message.error('密码至少6位');
            return;
        }
        const data = {
            newpwd,
            account,
            tel,
            telCaptcha
        };
        http('/admin/empl/updatePasswordByCaptcha', data, 'POST').then((response) => {
            message.success('修改成功！');
            this.setState({ status: 1, pwd: '', newpwd: '', alreadySetCaptcha: '', telCaptcha: '', tel: '' });
        }).catch((e) => {
            message.error(e.message);
        });
    }

    _renderError = (error) => {
        if (error) {
            return (
                <div className={styles.error}>
                    <img className={styles.jinzhi_img} src={require('@/assets/image/jinzhi.png').default} />
                    <div className={styles.jinzhi_text}>您输入的账号密码不匹配，请重新输入</div>
                </div>
            );
        }
    }

    // 忘记密码
    _forgetPw = () => {
        this.setState({ status: 2, alreadySetCaptcha: false });
    }

    // 渲染状态场景
    _renderContent = () => {
        const { status } = this.state;
        switch (status) {
            case 1:
                return this._status1();
            case 2:
                return this._status2();
            case 3:
                return this._status3();
        }
    }

    // 场景1
    _status1 = () => {
        const { account, pwd, keepPwd, error } = this.state;
        return <div className={styles.handle}>
            <div className={styles.title}>
                账号密码登录
            </div>
            <XInput
                style={{ marginLeft: '-10px' }}
                inputStyle={{ width: '390px' }}
                placeholder='账号'
                prefix={<UserOutlined style={{ color: '#37BB8F', fontSize: '16px', marginRight: '4px' }} />}
                value={account}
                bindThis={this}
                bindName='account'
            />
            <XInputPassword
                style={{ marginLeft: '-10px', marginTop: '20px' }}
                inputStyle={{ width: '390px' }}
                placeholder="密码"

                prefix={<LockOutlined style={{ color: '#37BB8F', fontSize: '16px', marginRight: '4px' }} />}
                visibilityToggle={true}
                value={pwd}
                bindThis={this}
                bindName='pwd'
            />
            {/* <div className={styles.rememberme}>
                <Checkbox
                    style={{ width: '110px' }}
                    checked={keepPwd}
                    onChange={this._keepPwdChange}
                >
                    记住密码
                </Checkbox>
                <div className={styles.forgetPw} onClick={this._forgetPw}>
                    忘记密码
                </div>
            </div> */}
            <div className={styles.submit}>
                <Button type="primary" onClick={this._loginHandle}>登录</Button>
            </div>
            {this._renderError(error)}
        </div>;
    }

    // 场景2
    _status2 = () => {
        const { account, tel, telCaptcha, alreadySetCaptcha, time } = this.state;
        const captchaLabel = alreadySetCaptcha ? '短信已发送(' + time + ')' : '获取验证码';

        return <div className={styles.handle}>
            <XInput
                style={{ marginLeft: '-10px' }}
                inputStyle={{ width: '424px' }}
                placeholder='账号'
                prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                value={account}
                bindThis={this}
                bindName='account'
            />
            <XInput
                style={{ marginLeft: '-10px', marginTop: '20px' }}
                inputStyle={{ width: '424px' }}
                placeholder='请输入手机号'
                prefix={<Icon type="phone" style={{ color: 'rgba(0,0,0,.25)' }} />}
                value={tel}
                bindThis={this}
                bindName='tel'
            />
            <div style={{ display: 'flex', marginTop: '30px' }}>
                <XInput
                    style={{ marginLeft: '-10px' }}
                    inputStyle={{ width: '286px' }}
                    placeholder='请输入验证码'
                    prefix={<Icon type="key" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    value={telCaptcha}
                    bindThis={this}
                    bindName='telCaptcha'
                />
                <XOKButton
                    style={{ marginLeft: '10px', flex: 1 }}
                    label={captchaLabel}
                    onClick={this._getCaptcha}
                    disabled={alreadySetCaptcha}
                />
            </div>
            <div className={styles.submit}>
                <Button type="primary" onClick={this._next}>下一步</Button>
            </div>
            <div
                style={{ color: '#1890ff', fontSize: '16px', marginTop: '10px', cursor: 'pointer' }}
                onClick={() => {
                    clearInterval(this.timeID);
                    this.setState({ status: 1, telCaptcha: '', time: 60 });
                }}
            >
                返回登录
            </div>
        </div>;
    }

    // 场景3
    _status3 = () => {
        const { newpwd, newpwdSure } = this.state;

        return <div className={styles.handle}>
            <div
                style={{
                    textAlign: 'center',
                    fontSize: '24px',
                    color: 'white',
                    fontWeight: 'bold'
                }}
            >
                设置新密码
            </div>
            <XInputPassword
                style={{ marginLeft: '-10px', marginTop: '20px' }}
                inputStyle={{ width: '424px' }}
                placeholder="输入新密码"
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                value={newpwd}
                bindThis={this}
                bindName='newpwd'
            />
            <XInputPassword
                style={{ marginLeft: '-10px', marginTop: '20px' }}
                inputStyle={{ width: '424px' }}
                placeholder="确认新密码"
                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                value={newpwdSure}
                bindThis={this}
                bindName='newpwdSure'
            />
            <div className={styles.submit}>
                <Button type="primary" onClick={this._saveNewPw}>保存</Button>
            </div>
        </div>;
    }

    render() {
        return (
            <div className={styles.login}>
                <div className={styles.logo}>
                    <img className={styles.logo_img} src={require('@/assets/image/logo.png').default} />
                </div>
                <div className={styles.sys_name}>商云客服后台管理系统</div>
                <div className={styles.userinput}>
                    <div className={styles.bglayer}></div>
                    {this._renderContent()}
                </div>
            </div>
        );
    }
}

LoginBox.propTypes = {
    history: PropTypes.object,
    setFirstCardPath: PropTypes.func, // 存储一级导航路径的函数
    setSecondCardPath: PropTypes.func, // 存储二级导航路径的函数
    setStretchOpen: PropTypes.func, // 存储导航栏的伸展状态的函数
    cleanBreadcrumbPaths: PropTypes.func, // 清理面包屑路径数据
    setAuth: PropTypes.func, // 存储验证信息
};

export default connect(null, { setFirstCardPath, setSecondCardPath, setStretchOpen, cleanBreadcrumbPaths, setAuth })(LoginBox);